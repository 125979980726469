import { NavLink, Route, Routes } from "react-router-dom";
import Transactions from "./payments/Transactions";
import Customers from "./payments/Customers";
import Payouts from "./payments/Payouts";

const Payments = () => {
  return (
    <div className="p-10 py-8 flex flex-col gap-8">
      <div className="flex items-center gap-4">
        <NavLink
          to="transactions"
          className="p-3 px-5 text-sm rounded-lg font-medium max-w-fit flex items-center gap-2 transition c-nav-link"
        >
          Transactions
        </NavLink>
        <NavLink
          to="customers"
          className="p-3 px-5 text-sm rounded-lg font-medium max-w-fit flex items-center gap-2 transition c-nav-link"
        >
          Customers
        </NavLink>
        <NavLink
          to="payouts"
          className="p-3 px-5 text-sm rounded-lg font-medium max-w-fit flex items-center gap-2 transition c-nav-link"
        >
          Payouts
        </NavLink>
        <div className="flex-1"></div>
      </div>
      <Routes>
        <Route path="/" element={<Transactions />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/payouts" element={<Payouts />} />
      </Routes>
    </div>
  );
};

export default Payments;
