import React from 'react';
import { ArrowDown, ArrowUp, CloudArrowUp, DotsThreeVertical, Plus } from 'phosphor-react';
import TransactionsList from '../../components/TransactionsList';
import svgPath from '../../assets/images/chartsvg.svg';

const Dashboard = () => {
  return (
    <>
      <div className="bg-gray-50 py-5 md:py-10">
        <div className="p-5 md:p-10 flex flex-col gap-5 md:gap-8 bg-white">
          <div className="flex flex-col md:flex-row items-center justify-between py-5 md:py-8">
            <div className="text-center md:text-left">
              <div className="text-xl md:text-3xl font-medium text-deepBlue">
                Welcome back, Eve Healthcare
              </div>
              <div className="text-xs md:text-sm">Track, manage and forecast your customers and orders.</div>
            </div>
            <div className="flex flex-col md:flex-row gap-2 md:gap-4">
              <div className="p-2 md:p-3 px-4 md:px-5 border-gray-300 border rounded-lg font-bold text-xs md:text-sm text-deepBlue flex items-center gap-1 md:gap-2">
                <CloudArrowUp size={16} />
                <span className="hidden md:inline">Import</span>
              </div>
              <div className="p-2 md:p-3 px-4 md:px-5 border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-xs md:text-sm flex items-center gap-1 md:gap-2">
                <Plus size={16} />
                <span className="hidden md:inline">Add</span>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
            {/* Transaction card 1 */}
            <div className="border-2 border-gray-200 p-5 rounded-xl flex flex-col gap-5">
              <div className="flex items-center justify-between">
                <div className="font-medium">Balance (NGN)</div>
                <DotsThreeVertical className="text-gray-500" size={18} weight="bold" />
              </div>
              <div className="text-3xl font-semibold text-deepBlue">200,420.00</div>
              <div className="hidden md:flex items-center gap-2">
                <div className="p-3 px-5 border-gray-300 border rounded-lg font-bold text-sm text-deepBlue flex items-center justify-center flex-1">
                  <span>Withdraw</span>
                </div>
                <div className="p-3 px-5 border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm flex items-center justify-center flex-1">
                  <span>Top Up</span>
                </div>
              </div>
            </div>
            {/* Transaction card 2 */}
            <div className="border-2 border-gray-200 p-5 rounded-xl flex flex-col gap-5">
              <div className="flex items-center justify-between">
                <div className="font-medium">Successful Transactions</div>
                <DotsThreeVertical className="text-gray-500" size={18} weight="bold" />
              </div>
              <div className="text-3xl font-semibold text-deepBlue">2,420</div>
              <div className="flex">
                <div className="flex-1 flex items-center gap-2 text-green-500 font-medium">
                  <ArrowUp weight="bold" size={18} />
                  <div>40%</div>
                  <div className="text-gray-500">vs last month</div>
                </div>
                <div className="flex-1">
                  <img src={svgPath} alt="" />
                </div>
              </div>
            </div>
            {/* Transaction card 3 */}
            <div className="border-2 border-gray-200 p-5 rounded-xl flex flex-col gap-5">
              <div className="flex items-center justify-between">
                <div className="font-medium">Failed Transactions</div>
                <DotsThreeVertical className="text-gray-500" size={18} weight="bold" />
              </div>
              <div className="text-3xl font-semibold text-deepBlue">1,210</div>
              <div className="flex">
                <div className="flex-1 flex items-center gap-2 text-red-500 font-medium">
                  <ArrowDown weight="bold" size={18} />
                  <div>10%</div>
                  <div className="text-gray-500">vs last month</div>
                </div>
                <div className="flex-1">
                  <img src={svgPath} alt="" />
                </div>
              </div>
            </div>
            {/* Transaction card 4 */}
            <div className="border-2 border-gray-200 p-5 rounded-xl flex flex-col gap-5">
              <div className="flex items-center justify-between">
                <div className="font-medium">Pending Transactions</div>
                <DotsThreeVertical className="text-gray-500" size={18} weight="bold" />
              </div>
              <div className="text-3xl font-semibold text-deepBlue">316</div>
              <div className="flex">
                <div className="flex-1 flex items-center gap-2 text-green-500 font-medium">
                  <ArrowUp weight="bold" size={18} />
                  <div>20%</div>
                  <div className="text-gray-500">vs last month</div>
                </div>
                <div className="flex-1">
                  <img src={svgPath} alt="" />
                </div>
              </div>
            </div>
          </div>
          {/* Transaction list component */}
          <TransactionsList />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
