export interface BVNData {
  first_name: string;
  middle_name: string;
  last_name: string;
  phone_1: string;
  phone_2: string;
  address: string;
  state_of_residence: StateOfResidence;
  lga_of_residence: LgaOfResidence;
}

export interface StateOfResidence {
  id: number;
  description: string;
}

export interface LgaOfResidence {
  id: number;
  description: string;
}

export type Country = {
  country_key: string;
  description: string;
};

export type APIResponse<T = any> = {
  message: string;
  success: boolean;
  errors?: Record<string, any>;
  data?: T;
  [key: string]: any;
};

export type Region = {
  id: string;
  description: string;
};

export interface User {
  account_type: AccountType;
  first_name: string;
  middle_name: string;
  last_name: string;
  phone_1: string;
  phone_2: string;
  address: string;
  state_of_residence: StateOfResidence;
  lga_of_residence: LgaOfResidence;
  profile_pics: string;
  wallet: Wallet;
}

export interface AccountType {
  id: number;
  description: string;
}
export interface Wallet {
  visaro_balance: string;
  visaro_credit: string;
  status: string;
}

export interface DashboardData {
  onboarding: Onboarding;
  wallet: Wallet;
  bnpl_services: BnplService[];
  activities_log: ActivitiesLog[];
  registered_hospital: RegisteredHospital[];
}

export interface BnplService {
  bnpl_service_name: string;
  thumbnails: string;
  display_img: string;
}

export interface ActivitiesLog {
  activity_description: string;
  created_at: string;
}

export interface RegisteredHospital {
  hospital_name: string;
  thumbnail: string;
  display_img: string;
}

export interface Onboarding {
  bvn_verification: boolean;
  profile_picture_upload: boolean;
  account_type: string;
  setup_trans_pin: boolean;
  phone_no_verification: boolean;
}

export type UsernameEnquiryData = {
  full_name: string;
};

export type BeneficiaryEnquiryData = {
  full_name: string;
};

export enum OTP_TYPES {
  LoginVerification = "1",
  ForgotPasswordVerification = "2",
  PhoneNumberVerification = "3",
  TransactionPin = "4",
}

export interface Bank {
  bank_code: string;
  bank_name: string;
  display_img: string;
}

export type TransferType = "inter" | "intra";

export interface TransferPayload {
  amount: string;
  transaction_pin: string;
  naration: string;
}

export interface VisaroTransferPayload extends TransferPayload {
  username: string;
}

export interface BankTransferPayload extends TransferPayload {
  to_bank_code?: string;
  to_account_no: string;
}

export interface TransferData {
  trans_id: string;
  session_id: string;
  reference: string;
}
