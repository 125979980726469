import {
  ArrowsCounterClockwise,
  CreditCard,
  EnvelopeSimple,
  Heart,
  IconProps,
  MagnifyingGlass,
  PlusCircle,
  XCircle,
} from "phosphor-react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import NewletterForm from "../components/NewletterForm";
import { useEffect } from "react";

const faqs: {
  question: string;
  icon: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >;
  answer: string;
}[] = [
  {
    question: "Is there a free trial available?",
    icon: Heart,
    answer:
      "Yes, you can try us for free for 30 days. Our friendly team will work with you to get you up and running as soon as possible.",
  },
  {
    question: "Can I change my plan later?",
    icon: ArrowsCounterClockwise,
    answer:
      "Of course. Our pricing scales with your company. Chat to our friendly team to find a solution that works for you.",
  },
  {
    question: "What is your cancellation policy?",
    icon: XCircle,
    answer:
      "We understand that things change. You can cancel your plan at any time and we’ll refund you the difference already paid.",
  },
  {
    question: "Can other info be added to an invoice?",
    icon: PlusCircle,
    answer:
      "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
  },
  {
    question: "How does billing work?",
    icon: CreditCard,
    answer:
      "Plans are per workspace, not per account. You can upgrade one workspace, and still have any number of free workspaces.",
  },
  {
    question: "How do I change my account email?",
    icon: EnvelopeSimple,
    answer:
      "You can change the email address associated with your account by going to untitled.com/account from a laptop or desktop.",
  },
];

const FAQ = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Navbar />
      <header className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg bg-[#F9FAFB]">
        <div className="flex flex-col gap-5 text-center">
          <div className="font-bold text-visaro-orange">FAQs</div>
          <div className="text-4xl md:text-4xl lg:text-6xl max-w-5xl font-bold text-center">
            Ask us anything
          </div>
        </div>
        <div className="text-gray-500 text-center">
          Need something cleared up? Here are our most frequently asked
          questions.
        </div>
        <form className="flex flex-col gap-8">
          <div className="border border-gray-300 rounded-lg flex items-center px-4">
            <MagnifyingGlass />
            <input
              className="flex-1 p-4 py-3 focus:outline-none bg-transparent"
              type="text"
              placeholder="Search"
            />
          </div>
        </form>
      </header>
      <section className="p-20 px-5 md:px-20 gap-20 flex flex-col text-lg bg-gray-100">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {faqs.map((faq, i) => {
            return (
              <div key={i} className="flex flex-col items-start gap-5">
                <div className="p-3 bg-visaro-orange/10 rounded-full">
                  <faq.icon className="text-visaro-orange" size={20} />
                </div>
                <div className="font-medium">{faq.question}</div>
                <div className="text-gray-500 text-base">{faq.answer}</div>
              </div>
            );
          })}
        </div>
        <div className="bg-white p-10 rounded-xl flex flex-col md:flex-row justify-between items-start md:items-center gap-8 w-full">
          <div className="flex flex-col gap-2 items-start">
            <div className="font-medium text-xl">Still have questions?</div>
            <div className="text-gray-500 text-base">
              Still have questions? Can’t find the answer you’re looking for?
              Please chat to our friendly team.
            </div>
          </div>
          <Link
            to="/contact-us"
            className="p-3 px-5 border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
          >
            Get in Touch
          </Link>
        </div>
      </section>
      <section className="p-20 px-5 md:px-20 gap-20 flex flex-col text-lg">
        <div className="flex flex-col-reverse md:flex-row gap-20 md:gap-40 items-center">
          <div className="flex flex-1 flex-col gap-8">
            <div className="flex flex-1 flex-col gap-5">
              <div className="font-medium text-visaro-primary-700">
                Get to know us
              </div>
              <div className="text-deepBlue text-4xl font-bold">
                We’re just getting started
              </div>
            </div>
            <div className="text-gray-500">
              Visaro is growing fast, and we are always looking for passionate,
              dynamic, and talented individuals to join our distributed team all
              around the world. <br />
              <br />
              Our philisophy is simple — hire a team of diverse, passionate
              people and foster a culture that empowers you to do you best work.
              Read more about
            </div>
            <div>
              <div className="hidden md:flex items-center gap-4 ">
                <Link
                  to="/about"
                  className="p-3 px-5 border-gray-300 border rounded-lg font-bold text-sm text-deepBlue"
                >
                  About Us
                </Link>
                <Link
                  to="/careers"
                  className="p-3 px-5 border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
                >
                  See Open Roles
                </Link>
              </div>
            </div>
          </div>
          <div className="flex-1 relative flex justify-center items-center">
            <img
              src={require("../assets/images/visaro-glass-building.png")}
              alt="hand-with-phone"
              className="rounded-3xl"
            />
          </div>
        </div>
      </section>
      <div className="hidden md:block">
        <NewletterForm />
      </div>
      <Footer />
    </div>
  );
};

export default FAQ;
