import React, { useEffect } from 'react';

const TripBookingWebView = () => {
  const handleNavigation = event => {
    if (event.target.src === 'https://ccpweb.test.vggdev.com/') {
      console.log('The iframe has navigated to the home page.');
    }
  };

  const handleMessage = message => {
    console.log('Received message:', message.data);
  };

  const customerName = 'Oliyide Adeoluwa';
  const customerId = '12345';
  const webUrl = `https://tripswebwidget.staging.vggdev.com/?MerchantCode=VISA&MerchantHash=a3d174d829eaf55778187ea3c5b25a35ec21dd26054c48f2d42cb337db0cb0d9b6a86061d4bae642c60cd23341cbf4a75e7a4728d30b512808a2b3ad6cf31874&CustomerName=${customerName}&CustomerId=${customerId}`;

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []); 

  return (
    <div>
      <iframe
        src={webUrl}
        title="Trip Booking Iframe"
        onLoad={handleNavigation}
        style={{ width: '100%', height: '600px', border: 'none' }}
      />
    </div>
  );
};

export default TripBookingWebView;
