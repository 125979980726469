import { CaretDown } from "phosphor-react";
import { FC, useState } from "react";

const SelectInput: FC<{
  placeholder?: string;
  disabled?: boolean;
  selected: string;
  data?: { id: string; value: string }[];
  onSelect: (id: string) => void;
}> = ({ placeholder, disabled, data = [], selected, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <button
      type="button"
      onBlur={() => setIsOpen(false)}
      className={`relative rounded-lg text-left border border-gray-300 shadow  w-full cursor-pointer text-[#4d4d4d] ${
        disabled && "opacity-50 cursor-not-allowed bg-gray-100"
      }`}
    >
      <div
        onClick={() => {
          setIsOpen(true);
        }}
        className="flex items-center justify-between px-4 py-3"
      >
        <div>
          {data?.find((i) => i.id === selected)?.value ||
            placeholder ||
            "Select"}
        </div>
        <CaretDown weight="fill" />
      </div>
      {isOpen && (
        <div className="absolute top-full mt-2 max-h-40 overflow-scroll z-50 bg-white w-full left-0 rounded-lg border border-gray-300">
          {data.map((option, i) => {
            return (
              <div
                key={i}
                onClick={(e) => {
                  onSelect(option.id);
                  setIsOpen(false);
                  e.stopPropagation();
                }}
                className="hover:bg-gray-200 py-2 m-0 px-2"
              >
                {option.value}
              </div>
            );
          })}
        </div>
      )}
    </button>
  );
};

export default SelectInput;
