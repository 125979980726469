import { Link } from "react-router-dom";
import logo from "../../../assets/images/visaro-logo.svg";
import { Circle, RadioButton } from "phosphor-react";
import { useContext } from "react";
import { SignUpContext } from "../../SignUp";

const SelectBusinessType = () => {
  const { businessType, setBusinessType } = useContext(SignUpContext);
  return (
    <div>
      <nav className="p-4 md:px-10 flex items-center justify-center border border-gray-100">
        <div className="max-w-3xl w-full flex items-center justify-center">
          <Link to="/">
            <img src={logo} alt="Visaro's Logo" />
          </Link>
        </div>
      </nav>
      <div className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg">
        <div className="flex flex-col justify-center items-center gap-2">
          <div className="text-4xl md:text-3xl lg:text-5xl max-w-3xl font-bold text-center">
            Select business type
          </div>
          <div className="text-gray-500 text-center max-w-lg">
            To serve you properly, let us know your business type
          </div>
        </div>
        <div className="w-full flex-col gap-5 flex items-center justify-center text-base">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-20 gap-y-10 py-5">
            <div
              className="flex flex-col gap-5 border border-gray-300 p-10 rounded-2xl shadow-xl"
              onClick={() => setBusinessType("sole")}
            >
              <div>
                {businessType === "sole" ? (
                  <RadioButton
                    weight="fill"
                    className="text-deepBlue"
                    size={40}
                    strokeWidth={1}
                  />
                ) : (
                  <Circle
                    weight="regular"
                    className="text-gray-300"
                    size={40}
                    strokeWidth={1}
                  />
                )}
              </div>
              <div className="text-4xl font-semibold">
                Sole proprietorship/ Partnership
              </div>
              <div className="text-gray-500">
                Ideal for small businesses and partnerships. Get paid online,
                in-store, or on the go with easy control.
              </div>
            </div>
            <div
              className="flex flex-col gap-5 border border-gray-300 p-10 rounded-2xl shadow-xl"
              onClick={() => setBusinessType("corporation")}
            >
              <div>
                {businessType === "corporation" ? (
                  <RadioButton
                    weight="fill"
                    className="text-deepBlue"
                    size={40}
                    strokeWidth={1}
                  />
                ) : (
                  <Circle
                    weight="regular"
                    className="text-gray-300"
                    size={40}
                    strokeWidth={1}
                  />
                )}
              </div>
              <div className="text-4xl font-semibold">Corporation</div>
              <div className="text-gray-500">
                Tailored for larger businesses.
              </div>
            </div>
          </div>
          <div className="max-w-sm w-full">
            <Link
              to="/sign-up/business/complete-business-profile"
              className="p-4 px-5 border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm w-full block text-center"
            >
              Continue
            </Link>
          </div>
          .
        </div>
      </div>
    </div>
  );
};

export default SelectBusinessType;
