import {
  ArrowLeft,
  ArrowRight,
  CloudArrowUp,
  FunnelSimple,
  MagnifyingGlass,
  Plus,
} from "phosphor-react";
import { Fragment } from "react";
import CustomersListItem from "./CustomersListItem";

const PayoutsList = () => {
  return (
    <div className="flex flex-col gap-8">
      <div className="flex items-center gap-4">
        <button className="p-3 px-5 text-sm border-gray-300 border rounded-lg font-medium max-w-fit flex items-center gap-2 hover:bg-gray-100 transition">
          <FunnelSimple size={18} />
          More Filters
        </button>
        <div className="border border-gray-300 rounded-lg flex items-center px-4 text-sm">
          <MagnifyingGlass />
          <input
            className="flex-1 p-3 focus:outline-none"
            type="search"
            placeholder="Search"
          />
          <ArrowRight />
        </div>
        <div className="flex-1"></div>
        <div className="hidden md:flex items-center gap-2">
          <button className="p-3 px-5 border-gray-300 border rounded-lg font-bold text-sm text-deepBlue flex items-center gap-2 hover:bg-gray-100 transition">
            <CloudArrowUp size={18} />
            <span>Export CSV</span>
          </button>
          <button className="p-3 px-5 border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm flex items-center gap-2 transition">
            <Plus size={18} />
            <span>Add Customer</span>
          </button>
        </div>
      </div>
      <div className="border-2 border-gray-200 rounded-xl grid grid-cols-7 text-sm text-gray-500 overflow-hidden">
        <Fragment>
          <div className="bg-gray-100 py-4 font-medium px-8 flex gap-4 items-center">
            Status
          </div>
          <div className="bg-gray-100 border-b border-gray-200  py-4 font-medium px-8 col-span-2">
            Customer
          </div>
          <div className="bg-gray-100 border-b border-gray-200  py-4 font-medium px-8 col-span-2">
            Phone
          </div>
          <div className="bg-gray-100 border-b border-gray-200  py-4 font-medium px-8 col-span-2">
            Added On
          </div>
        </Fragment>
        <div className="col-span-full">
          {new Array(4).fill(0).map((_, key) => {
            return <CustomersListItem key={key} />;
          })}
        </div>
        <div className="flex items-center gap-4 justify-between col-span-full px-8 py-5">
          <button className="py-2 px-4 border text-gray-700 rounded-lg flex items-center gap-2 font-medium hover:bg-deepBlue/5 transition">
            <ArrowLeft weight="bold" />
            <span>Previous</span>
          </button>
          <div className="flex items-center gap-4">
            <div className="bg-visaro-primary-700/20 transition cursor-pointer w-8 h-8 aspect-square font-medium text-gray-700 rounded-lg grid place-items-center">
              1
            </div>
            <div className="hover:bg-visaro-primary-700/5 transition cursor-pointer w-8 h-8 aspect-square font-medium text-gray-700 rounded-lg grid place-items-center">
              2
            </div>
            <div className="hover:bg-visaro-primary-700/5 transition cursor-pointer w-8 h-8 aspect-square font-medium text-gray-700 rounded-lg grid place-items-center">
              3
            </div>
            <div>...</div>
            <div className="hover:bg-visaro-primary-700/5 transition cursor-pointer w-8 h-8 aspect-square font-medium text-gray-700 rounded-lg grid place-items-center">
              8
            </div>
            <div className="hover:bg-visaro-primary-700/5 transition cursor-pointer w-8 h-8 aspect-square font-medium text-gray-700 rounded-lg grid place-items-center">
              9
            </div>
            <div className="hover:bg-visaro-primary-700/5 transition cursor-pointer w-8 h-8 aspect-square font-medium text-gray-700 rounded-lg grid place-items-center">
              10
            </div>
          </div>
          <button className="py-2 px-4 border text-gray-700 rounded-lg flex items-center gap-2 font-medium hover:bg-deepBlue/5 transition">
            <span>Next</span>
            <ArrowRight weight="bold" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PayoutsList;
