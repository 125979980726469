import React, { useRef, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { ErrorMessage, Field, Form, Formik } from "formik";
import useProfileUpdate from "../../../hooks/useProfileUpdate";
import { HashLoader } from "react-spinners";
import { Camera } from "phosphor-react";

const Profile = () => {
  const { user } = useAuth();

  const {
    updateProfile,
    updatingProfile,
    isProfileUpdateError,
    profileUpdateError,
  } = useProfileUpdate();

  const [profileImg, setProfileImg] = useState<File | null>(null);
  const imageRef = useRef<HTMLInputElement>(null);

  return (
    <div className="bg-gray-50 px-10 py-10 flex items-center justify-center">
      {updatingProfile && (
        <div className="fixed top-0 z-50 left-0 h-screen w-screen flex items-center justify-center bg-black/20 backdrop-blur-sm">
          <HashLoader />
        </div>
      )}
      <div className="flex flex-col gap-8 max-w-3xl w-full">
        <div className="flex gap-10 items-center">
          <div
            className="relative cursor-pointer"
            onClick={() => imageRef.current?.click()}
          >
            <img
              src={
                profileImg
                  ? URL.createObjectURL(profileImg)
                  : user?.profile_pics || ""
              }
              alt="you"
              className="w-32 aspect-square rounded-full bg-white"
            />
            <div className="bg-gray-500/50 text-white absolute bottom-1 left-1/2 -translate-x-1/2 p-2 rounded-full">
              <Camera />
            </div>
          </div>
          <div>
            <div className="font-medium text-deepBlue text-lg">
              Personal Info
            </div>
            <div className="text-gray-700">
              Update your photo and personal details here.
            </div>
            {isProfileUpdateError && (
              <div className="text-red-500 bg-red-100 w-fit p-2 text-sm mt-2 rounded-lg px-4 font-medium">
                {profileUpdateError?.message}
              </div>
            )}
          </div>
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            firstname: user?.first_name,
            lastname: user?.last_name,
            phoneNumber: user?.phone_2,
            address: user?.address
              ? `${user?.address || ""}, ${
                  user.state_of_residence?.description || ""
                }`
              : "",
          }}
          onSubmit={(values) => {
            const data: Record<any, any> = {
              account_type: user?.account_type,
              phone_1: user?.phone_1,
              phone_2: values.phoneNumber,
            };

            if (profileImg) {
              data.profile_pics = {
                file: profileImg,
                name: profileImg.name,
              };
            }
            updateProfile({
              ...data,
            });
          }}
        >
          {({ dirty }) => {
            return (
              <Form className="bg-white rounded-lg w-full shadow-sm flex-col gap-5 flex">
                <div className="p-10 py-10 w-full flex-col gap-5 flex">
                  <input
                    type="file"
                    hidden
                    ref={imageRef}
                    name="profile_img"
                    onChange={(e) => {
                      if (e.target.files && e.target.files[0]) {
                        setProfileImg(e.target.files[0]);
                      }
                    }}
                    id="profile_img"
                    accept="image/png, image/jpeg, image/jpg"
                  />
                  <div className="flex gap-5">
                    <div className="flex flex-col gap-2 w-full flex-1">
                      <label
                        htmlFor="firstname"
                        className="text-sm font-bold text-gray-700"
                      >
                        First Name*
                      </label>
                      <Field
                        disabled
                        type="text"
                        id="firstname"
                        name="firstname"
                        placeholder="Enter your First Name"
                        className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                      />
                      <ErrorMessage
                        name="firstname"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="flex flex-col gap-2 w-full flex-1">
                      <label
                        htmlFor="phoneNumber"
                        className="text-sm font-bold text-gray-700"
                      >
                        Last Name*
                      </label>
                      <Field
                        type="text"
                        disabled
                        id="lastname"
                        name="lastname"
                        placeholder="Enter your Last Name"
                        className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                      />
                      <ErrorMessage
                        name="lastname"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 w-full ">
                    <label
                      htmlFor="email"
                      className="text-sm font-bold text-gray-700"
                    >
                      Email*
                    </label>
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                      className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="flex flex-col gap-2 w-full ">
                    <label
                      htmlFor="phoneNumber"
                      className="text-sm font-bold text-gray-700"
                    >
                      Phone Number*
                    </label>
                    <Field
                      type="tel"
                      id="phoneNumber"
                      name="phoneNumber"
                      placeholder="Enter your Phone Number"
                      className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                    />
                    <ErrorMessage
                      name="phoneNumber"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="flex flex-col gap-2 w-full ">
                    <label
                      htmlFor="address"
                      className="text-sm font-bold text-gray-700"
                    >
                      Address*
                    </label>
                    <Field
                      disabled
                      type="address"
                      id="address"
                      name="address"
                      placeholder="Enter your Address"
                      className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                    />
                    <ErrorMessage
                      name="address"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
                <div className="border-t border-gray-100 p-10 py-5 flex items-center justify-between">
                  <button
                    type="button"
                    className="p-4 px-5 border-red-100 border rounded-lg font-bold bg-red-100 text-red-500 text-sm"
                  >
                    Log Out
                  </button>
                  <button
                    disabled={profileImg == null || !dirty}
                    type="submit"
                    className="p-4 px-5 border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
                  >
                    Save Changes
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Profile;
