import { Bell, Gear } from "phosphor-react";
import logo from "../assets/images/visaro-logo-white.svg";

import { NavLink } from "react-router-dom";
import { FC } from "react";
import { useAuth } from "../context/AuthContext";

const DashboardNavbar: FC<{
  pathPrefix: string;
}> = ({ pathPrefix }) => {
  const { user } = useAuth();
  return (
    <nav className="p-4 md:px-10 flex items-center justify-between bg-deepBlue text-white">
      <div className="flex items-center gap-10">
        <NavLink to="">
          <img src={logo} alt="Visaro's Logo" />
        </NavLink>
        <NavLink
          to={`home`}
          className="items-center gap-2 hidden md:flex nav-link"
        >
          <span>Dashboard</span>
        </NavLink>
        {pathPrefix === "business" && (
          <NavLink
            to={`payments/transactions`}
            className="hidden md:flex items-center gap-2 nav-link"
          >
            <span>Payments</span>
          </NavLink>
        )}
        {pathPrefix === "personal" && (
          <NavLink
            to={`send`}
            className="hidden md:flex items-center gap-2 nav-link"
          >
            <span>Send</span>
          </NavLink>
        )}
        <NavLink
          to={`wallet`}
          className="hidden md:flex items-center gap-2 nav-link"
        >
          <span>Wallet</span>
        </NavLink>
        {pathPrefix === "business" && (
          <NavLink
            to={`commerce`}
            className="hidden md:flex items-center gap-2 nav-link"
          >
            <span>Commerce</span>
          </NavLink>
        )}
        {pathPrefix === "personal" && (
          <NavLink
            to={`activity`}
            className="hidden md:flex items-center gap-2 nav-link"
          >
            <span>Activity</span>
          </NavLink>
        )}
        <NavLink
          to={`help`}
          className="hidden md:flex items-center gap-2 nav-link"
        >
          <span>Help</span>
        </NavLink>
      </div>
      <div className="hidden md:flex items-center gap-4">
        <NavLink to={`settings`} className="p-2 bg-visaro-gray-700 rounded-lg">
          <Gear size={20} />
        </NavLink>
        <NavLink to={`notifications`} className="rounded-lg">
          <Bell size={20} />
        </NavLink>
        <NavLink to={`profile`} className="rounded-lg">
          <img
            src={user?.profile_pics || ""}
            alt="you"
            className="w-10 aspect-square rounded-full bg-white"
          />
        </NavLink>
      </div>
    </nav>
  );
};

export default DashboardNavbar;
