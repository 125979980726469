import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/visaro-logo.svg";
import { useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import { CheckCircle, FolderPlus, User } from "phosphor-react";
import CountryInput from "../../../components/CountryInput";
import RegionInput from "../../../components/RegionInput";

const schema = yup.object().shape({
  business_name: yup.string().required("Business name is required"),
  business_address: yup.string().required("Business address is required"),
  business_email: yup
    .string()
    .email("Invalid email address")
    .required("Business email is required"),
  country: yup.string().required("Please select a country"),
  region: yup.string().required("Please select a state/region"),
});

const CompleteBusinessProfile = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const navigate = useNavigate();

  return (
    <div>
      <Formik
        initialValues={{
          business_name: "",
          business_email: "",
          business_address: "",
          country: "",
          region: "",
        }}
        validationSchema={schema}
        onSubmit={(values, { resetForm }) => {
          navigate(`/sign-up/business/attach-documents`, {
            state: { data: values },
          });
        }}
      >
        {({ isValid, dirty, values, setFieldValue }) => {
          return (
            <div>
              <nav className="p-4 md:px-10 flex items-center justify-center border border-gray-100">
                <div className="max-w-3xl w-full flex items-center justify-center">
                  <Link to="/">
                    <img src={logo} alt="Visaro's Logo" />
                  </Link>
                </div>
              </nav>
              <div className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg pt-10">
                <div className="max-w-4xl w-full">
                  <div className="flex items-center gap-2">
                    <div className="bg-visaro-orange p-2 rounded-full">
                      <User className="text-white" />
                    </div>
                    <hr className="flex-1 border-visaro-orange border" />
                    <div className="bg-gray-100 text-gray-500 p-2 rounded-full">
                      <FolderPlus />
                    </div>
                    <hr className="flex-1 border border-gray-200" />
                    <div className="bg-gray-100 text-gray-500 p-2 rounded-full">
                      <CheckCircle />
                    </div>
                  </div>
                </div>
                <div className="flex items-center flex-col gap-2">
                  <div className="text-xl max-w-3xl font-medium text-center">
                    Complete your Business Profile
                  </div>
                  <div className="text-gray-500 text-center max-w-lg">
                    Kindly fill your business details to create your account
                  </div>
                </div>
                <Form className="w-full flex-col gap-5 flex items-center justify-center text-base">
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <label
                      htmlFor="business_name"
                      className="text-sm font-bold text-gray-700"
                    >
                      Business Name*
                    </label>
                    <Field
                      type="text"
                      placeholder="Enter your business name"
                      name="business_name"
                      id="business_name"
                      className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                    />
                    <ErrorMessage
                      name="business_name"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <label
                      htmlFor="business_address"
                      className="text-sm font-bold text-gray-700"
                    >
                      Business Address*
                    </label>
                    <Field
                      type="text"
                      id="business_address"
                      name="business_address"
                      placeholder="Enter your business address"
                      className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                    />
                    <ErrorMessage
                      name="business_address"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <label
                      htmlFor="business_email"
                      className="text-sm font-bold text-gray-700"
                    >
                      Business Email*
                    </label>
                    <Field
                      autoComplete="off"
                      type="email"
                      id="business_email"
                      name="business_email"
                      placeholder="Enter your business email"
                      className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                    />
                    <ErrorMessage
                      name="business_email"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <label
                      htmlFor="country"
                      className="text-sm font-bold text-gray-700"
                    >
                      Country
                    </label>
                    <CountryInput
                      selected={values.country}
                      onSelect={(code) => setFieldValue("country", code)}
                    />
                    <ErrorMessage
                      name="country"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  {values.country !== "" && (
                    <div className="flex flex-col gap-2 max-w-md w-full">
                      <label
                        htmlFor="region"
                        className="text-sm font-bold text-gray-700"
                      >
                        State/Region
                      </label>
                      <RegionInput
                        countryKey={values.country}
                        selected={values.region}
                        onSelect={(id) => setFieldValue("region", id)}
                      />
                      <ErrorMessage
                        name="region"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  )}
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <button
                      disabled={!isValid || !dirty}
                      type="submit"
                      className="p-4 px-5 w-full border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
                    >
                      Continue
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default CompleteBusinessProfile;
