import { MutationOptions, useMutation } from "react-query";
import { API_URL } from "../../config";
import { APIResponse } from "../@types";
import { APIError, post } from "../helpers/api";

function useLogin<T>(
  options?: MutationOptions<APIResponse<T>, APIError, Record<any, any>>
) {
  const { isLoading, isError, mutate, error } = useMutation<
    APIResponse<T>,
    APIError,
    Record<any, any>
  >((variables: Record<any, any>) => {
    return post(API_URL + "/login", variables);
  }, options);

  return {
    login: mutate,
    isError,
    error,
    isLoading,
  };
}

export default useLogin;
