import { ErrorMessage, Field, Form, Formik } from "formik";
import { LockSimple } from "phosphor-react";
import { FC, useState } from "react";
import * as yup from "yup";
import { motion } from "framer-motion";

const schema = yup.object().shape({
  oldPin: yup.string().required("Old pin is required"),
  newPin: yup
    .string()
    .min(6, "Pin must be at least 8 characters long")
    .required("New pin is required"),
  confirmPin: yup
    .string()
    .min(6, "Pin must be at least 8 characters long")
    .required("Pin is required"),
});

const ChangePinModal: FC<{
  setShowPinModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setShowPinModal }) => {
  const [pinIsVisible, setPinIsVisible] = useState(false);

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
      className="fixed top-0 left-0 bg-deepBlue/80 backdrop-blur-sm w-full h-screen grid place-items-center p-5"
    >
      <Formik
        validationSchema={schema}
        initialValues={{
          oldPin: "",
          newPin: "",
          confirmPin: "",
        }}
        onSubmit={() => {}}
      >
        <Form className="bg-white max-w-sm w-full p-5 rounded-xl shadow-sm flex items-center flex-col gap-5">
          <div className="p-2 bg-gray-100 rounded-full ring-8 ring-gray-50">
            <LockSimple size={28} />
          </div>
          <div className="flex items-center flex-col">
            <div className="font-medium">Change Pin</div>
            <p className="text-gray-500 text-center">
              Enter your pin to make this change.
            </p>
          </div>
          <div className="flex flex-col gap-2 max-w-md w-full">
            <label htmlFor="pin" className="text-sm font-bold text-gray-700">
              Old Pin*
            </label>
            <div className="rounded-lg border border-gray-300 shadow flex items-center w-full relative overflow-hidden focus-within:outline-deepBlue focus-within:outline accent-deepBlue">
              <Field
                autoComplete="off"
                type={pinIsVisible ? "text" : "password"}
                id="oldPin"
                name="oldPin"
                placeholder="Enter your old pin"
                className="px-4 py-3 bg-transparent flex-1 rounded-lg focus:outline-none"
              />
              <div
                onClick={() => setPinIsVisible((prev) => !prev)}
                className="absolute right-0 m-4 cursor-pointer text-sm"
              >
                {pinIsVisible ? "Hide" : "Show"}
              </div>
            </div>
            <ErrorMessage
              name="oldPin"
              component={motion.div}
              initial={{
                opacity: 0,
                y: "-100%",
              }}
              animate={{
                opacity: 1,
                y: 0,
              }}
              className="text-red-500"
            />
          </div>
          <div className="flex flex-col gap-2 max-w-md w-full">
            <label htmlFor="pin" className="text-sm font-bold text-gray-700">
              New Pin*
            </label>
            <div className="rounded-lg border border-gray-300 shadow flex items-center w-full relative overflow-hidden focus-within:outline-deepBlue focus-within:outline accent-deepBlue">
              <Field
                autoComplete="off"
                type={pinIsVisible ? "text" : "password"}
                id="newPin"
                name="newPin"
                placeholder="Create a new pin"
                className="px-4 py-3 bg-transparent flex-1 rounded-lg focus:outline-none"
              />
              <div
                onClick={() => setPinIsVisible((prev) => !prev)}
                className="absolute right-0 m-4 cursor-pointer text-sm"
              >
                {pinIsVisible ? "Hide" : "Show"}
              </div>
            </div>
            <ErrorMessage
              name="newPin"
              component={motion.div}
              initial={{
                opacity: 0,
                y: "-100%",
              }}
              animate={{
                opacity: 1,
                y: 0,
              }}
              className="text-red-500"
            />
          </div>
          <div className="flex flex-col gap-2 max-w-md w-full">
            <label htmlFor="pin" className="text-sm font-bold text-gray-700">
              Confirm New Pin*
            </label>
            <div className="rounded-lg border border-gray-300 shadow flex items-center w-full relative overflow-hidden focus-within:outline-deepBlue focus-within:outline accent-deepBlue">
              <Field
                autoComplete="off"
                type={pinIsVisible ? "text" : "password"}
                id="confirmPin"
                name="confirmPin"
                placeholder="Confirm your new pin"
                className="px-4 py-3 bg-transparent flex-1 rounded-lg focus:outline-none"
              />
              <div
                onClick={() => setPinIsVisible((prev) => !prev)}
                className="absolute right-0 m-4 cursor-pointer text-sm"
              >
                {pinIsVisible ? "Hide" : "Show"}
              </div>
            </div>
            <ErrorMessage
              name="confirmPin"
              component={motion.div}
              initial={{
                opacity: 0,
                y: "-100%",
              }}
              animate={{
                opacity: 1,
                y: 0,
              }}
              className="text-red-500"
            />
          </div>
          <div className="flex items-center gap-5 w-full">
            <button
              onClick={() => setShowPinModal(false)}
              className="p-3 px-5 flex-1 border-red-100 border-2 rounded-lg font-bold bg-red-50/50 text-red-500 text-sm"
            >
              Cancel
            </button>
            <button className="p-3 px-5 flex-1 border-visaro-gray-700 border-2 rounded-lg font-bold bg-deepBlue text-white text-sm">
              Save
            </button>
          </div>
        </Form>
      </Formik>
    </motion.div>
  );
};

export default ChangePinModal;
