import logo from "../../assets/images/visaro-logo-avatar.png";
import logo_gray from "../../assets/images/visaro-avatar-gray.png";
import logo_visaro_credit from "../../assets/images/visaro-credit-avatar.png";
import {
  ArrowRight,
  CheckCircle,
  DotsThreeVertical,
  WarningCircle,
  X,
} from "phosphor-react";
import visaro_pay_apps from "../../assets/images/visaro-pay-apps.svg";
import mobile_preview from "../../assets/images/visaro-mobile-app.svg";
import { Link } from "react-router-dom";
import {
  Fragment,
  // useMemo,
  useRef,
} from "react";
import { useQuery } from "react-query";
import { get } from "../../helpers/api";
import { API_URL } from "../../../config";
import {
  APIResponse,
  DashboardData,
  // Onboarding
} from "../../@types";
import { formatAsMoney } from "../../helpers/numbers";
import React, { useState } from 'react';
import '../styles/BnplAviation.scss';


// function evaluateOnboardingTasks(onboarding: Onboarding) {
//   const totalTasks = 5; // Total number of tasks
//   let completedTasks = 0;

//   if (onboarding.account_type !== "Not Set") {
//     completedTasks++;
//   }

//   if (onboarding.bvn_verification) {
//     completedTasks++;
//   }

//   if (onboarding.profile_picture_upload) {
//     completedTasks++;
//   }

//   if (onboarding.phone_no_verification) {
//     completedTasks++;
//   }

//   if (onboarding.setup_trans_pin) {
//     completedTasks++;
//   }

//   return {
//     count: totalTasks,
//     done: completedTasks,
//   };
// }

// enum BoxType {
//   NewFlight = 'newFlight',
//   SwapTicket = 'swapTicket',
// }

type BoxType = 'newFlight' | 'swapTicket';

const Dashboard = () => {
  const cardRef = useRef<HTMLDivElement>(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedBox, setSelectedBox] = useState<BoxType | null>(null);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleAviationServiceClick = () => {
    toggleModal();
  };

  const handleProceed = () => {
    if (selectedBox === 'newFlight') {
      // Handle proceed for 'New Flight'
      console.log('Proceeding for New Flight');
    } else if (selectedBox === 'swapTicket') {
      // Handle proceed for 'Swap Ticket'
      console.log('Proceeding for Swap Ticket');
    }
  };

  // const handleProceed = () => {
  //   if (selectedBox === 'newFlight') {
  //     window.location.href = '/personal/trip-booking';
  //     console.log('Proceeding for New Flight');
  //   } else if (selectedBox === 'swapTicket') {
  //     // Handle proceed for 'Swap Ticket'
  //     console.log('Proceeding for Swap Ticket');
  //   }
  // };

  const { isLoading, data } = useQuery<APIResponse<DashboardData>>(
    "dashboard",
    () =>
      get(API_URL + "/dashboard", {
        byPassStatus: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
    {
      onSuccess(data) {
        console.log("Dashboard", data);
      },
      onError(error) {
        console.log("Dashboard", error);
      },
    }
  );

  // const onboardingTasks = useMemo(() => {
  //   if (data && data.data) {
  //     return evaluateOnboardingTasks(data?.data?.onboarding);
  //   }

  //   return {
  //     done: 0,
  //     count: 0,
  //   };
  // }, [data]);

  return (
    <div>
      <div className="grid lg:grid-cols-2 gap-5 p-5 lg:p-10 bg-gray-50">
        <div className="flex flex-col gap-5">
          <div className="col-span-1 bg-white drop-shadow-sm rounded-xl flex gap-5 items-center p-5">
            <img
              src={logo}
              alt="Visaro's Logo"
              className="w-14 aspect-square object-cover"
            />
            <div>
              <div className="font-medium text-lg">Get the Visaro App</div>
              <div className="text-gray-500">
                Explore more options with visaro
              </div>
            </div>
          </div>
          <div
            ref={cardRef}
            className="col-span-1 bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-5"
          >
            <div className="flex justify-between items-center">
              <div>Get the Visaro App</div>
              <X weight="bold" onClick={() => cardRef.current?.remove()} />
            </div>
            <div className="flex items-center justify-center">
              <img src={mobile_preview} alt="mobile-app" />
            </div>
            <div className="flex justify-between items-center">
              <a
                href="https://www.apple.com/app-store/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 font-medium opacity-80 hover:opacity-100 transition"
              >
                Download the Visaro App
              </a>
            </div>
          </div>
          {/* Services */}
          <Fragment>
          {isLoading ? (
              <div className="col-span-1 bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-5">
                <div className="c-shimmer-loading c-shimmer-loading__text"></div>
                <div className="flex items-center justify-between gap-5">
                  {new Array(3).fill(0).map((_, key) => {
                    return (
                      <div className="w-full flex flex-col gap-2" key={key}>
                        <div className="c-shimmer-loading rounded-2xl h-32 w-full object-cover" />
                        <div className="c-shimmer-loading c-shimmer-loading__text"></div>
                      </div>
                    );
                  })}
                </div>
                <div className="c-shimmer-loading c-shimmer-loading__text"></div>
              </div>
            ) : (
              data?.data && (
                <div className="col-span-1 bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-5">
                  <div className="flex justify-between items-center">
                    <div className="text-lg">BNPL Services</div>
                  </div>
                  <div className="overflow-x-auto w-full whitespace-nowrap py-4 custom-scroll-bar">
                    {data.data.bnpl_services.map((service, key) => {
                      return (
                        <div
                          className="flex-col gap-2 inline-flex mr-2.5"
                          key={key}
                          onClick={key === 0 ? handleAviationServiceClick : undefined}
                        >
                          <img
                            src={service.display_img}
                            alt="logo"
                            draggable={false}
                            className="rounded-2xl h-32 lg:h-36 aspect-video bg-gray-50 border object-cover"
                          />
                          <div>{service.bnpl_service_name}</div>
                        </div>
                      );
                    })}
                  </div>

                  <div className="flex justify-between items-center">
                    <button className="text-blue-600 font-medium opacity-80 hover:opacity-100 transition">
                      Show All
                    </button>
                  </div>
                </div>
              )
            )}
              {showModal && (
              <div className="modal">
                <div className="modal-content">
                  <h2>Aviation (BNPL)</h2>
                  <div className="main">
                    <Link
                      to="/personal/trip-booking"
                      className={`box ${selectedBox === 'newFlight' ? 'selected' : ''}`}
                      onClick={() => setSelectedBox('newFlight')}
                    >
                      <div className="box-content">
                        <div className="icon">✚</div>
                        <span>New Flight</span>
                        <p>Book upcoming flight</p>
                      </div>
                    </Link>

                    <Link
                      to="/personal/swap"
                      className={`box1 ${selectedBox === 'swapTicket' ? 'selected' : ''}`}
                      onClick={() => setSelectedBox('swapTicket')}
                    >
                      <div className="box-content">
                        <div className="icon">⇄</div>
                        <span>Swap ticket</span>
                        <p>Change existing ticket</p>
                      </div>
                    </Link>
                  </div>
                  <div className="buttons">
                    <button onClick={handleProceed}>Proceed</button>
                    <button onClick={toggleModal}>Cancel</button>
                  </div>
                </div>
              </div>
            )}
          </Fragment>

          {/* Activities */}
          <Fragment>
            {isLoading ? (
              <div className="col-span-1 bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-5">
                <div className="c-shimmer-loading c-shimmer-loading__text"></div>
                <div className="c-shimmer-loading c-shimmer-loading__text c-shimmer-loading__longText"></div>
                <div className="c-shimmer-loading c-shimmer-loading__text"></div>
              </div>
            ) : (
              <div className="col-span-1 bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-5">
                <div className="flex justify-between items-center">
                  <div className="text-lg">Recent Activities</div>
                </div>
                <div className="text-lg text-gray-500">
                  See when money comes in, and when it goes out. You’ll find
                  your recent Visaro activity here.
                </div>
                <div className="flex justify-between items-center">
                  <button className="text-blue-600 font-medium opacity-80 hover:opacity-100 transition">
                    Show All
                  </button>
                </div>
              </div>
            )}
          </Fragment>
        </div>
        <div className="flex flex-col gap-5 -order-1">
          {/* Wallet */}
          <Fragment>
            {isLoading ? (
              <div className="flex gap-5">
                <div className="flex-1 bg-white drop-shadow-sm rounded-xl flex flex-col gap-5 items-start p-5">
                  <div className="w-14 c-shimmer-loading c-shimmer-loading__circle"></div>
                  <div className="flex-1 flex flex-col gap-2 w-full">
                    <div className="c-shimmer-loading c-shimmer-loading__text"></div>
                    <div className="c-shimmer-loading c-shimmer-loading__text"></div>
                  </div>
                  <div className="flex gap-2 w-full">
                    <div className="c-shimmer-loading c-shimmer-loading__button flex-1"></div>
                    <div className="c-shimmer-loading c-shimmer-loading__button flex-1"></div>
                  </div>
                </div>
                <div className="flex-1 bg-white drop-shadow-sm rounded-xl flex flex-col gap-5 items-start p-5">
                  <div className="w-14 c-shimmer-loading c-shimmer-loading__circle"></div>
                  <div className="flex-1 flex flex-col gap-2 w-full">
                    <div className="c-shimmer-loading c-shimmer-loading__text"></div>
                    <div className="c-shimmer-loading c-shimmer-loading__text"></div>
                  </div>
                  <div className="flex-1 flex flex-col gap-2 w-full">
                    <div className="c-shimmer-loading c-shimmer-loading__text"></div>
                    <div className="c-shimmer-loading c-shimmer-loading__text"></div>
                  </div>
                </div>
              </div>
            ) : (
              data?.data && (
                <div className="flex gap-5">
                  <div className="flex-1 bg-white drop-shadow-sm rounded-xl flex flex-col gap-5 items-start p-5">
                    <img
                      src={logo_gray}
                      alt="Visaro's Logo"
                      className="w-14 aspect-square object-cover"
                    />
                    <div className="flex-1 flex flex-col gap-2">
                      <div className="text-gray-500 font-medium text-sm">
                        Visaro Balance
                      </div>
                      <div className="text-3xl font-medium">
                        ₦ {formatAsMoney(data?.data?.wallet.visaro_balance)}
                      </div>
                    </div>
                    <div className="flex gap-2 w-full">
                      <Link
                        to="/personal/send"
                        className="p-2 py-3 text-center flex-1 border-visaro-orange text-visaro-orange border-2 rounded-xl font-medium"
                      >
                        Send
                      </Link>
                      <Link
                        to="send"
                        className="p-2 py-3 text-center flex-1 bg-visaro-orange text-white rounded-xl font-medium"
                      >
                        Request
                      </Link>
                    </div>
                  </div>
                  <div className="flex-1 bg-white drop-shadow-sm rounded-xl flex flex-col gap-5 items-start p-5">
                    <img
                      src={logo_visaro_credit}
                      alt="Visaro's Logo"
                      className="w-14 aspect-square object-cover"
                    />
                    <div className="flex-1 flex flex-col gap-2">
                      <div className="text-gray-500 font-medium text-sm">
                        Visaro Credit
                      </div>
                      <div className="text-3xl font-medium">
                        ₦ {formatAsMoney(data?.data?.wallet.visaro_credit)}
                      </div>
                      <div className="text-gray-500 font-medium text-sm">
                        Current Balance
                      </div>
                    </div>
                    <div className="text-gray-500 font-medium text-sm">
                      No Payment Due
                    </div>
                  </div>
                </div>
              )
            )}
          </Fragment>
          {/* Onboarding */}
          <Fragment>
            {isLoading ? (
              <div className="col-span-1 bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-5">
                <div className="flex items-center gap-5">
                  <div className="w-14 c-shimmer-loading c-shimmer-loading__circle"></div>
                  <div className="c-shimmer-loading c-shimmer-loading__text c-shimmer-loading__longText"></div>
                </div>
                <div className="c-shimmer-loading c-shimmer-loading__text"></div>
                <div className="c-shimmer-loading c-shimmer-loading__text"></div>
              </div>
            ) : (
              data &&
              data.data && (
                <div className="col-span-full bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-4">
                  <div className="flex gap-4 items-center">
                    {/* <div className="bg-red-300">
                      <CircularProgress />
                    </div> */}
                    {/* <div className="single-chart">
                      <svg
                        viewBox="0 0 36 36"
                        className="circular-chart orange"
                      >
                        <path
                          className="circle-bg"
                          d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <path
                          className="circle"
                          strokeDasharray="30, 100"
                          d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <text x="18" y="20.35" className="percentage">
                          {onboardingTasks.done}/{onboardingTasks.count}
                        </text>
                      </svg>
                    </div> */}
                    <div className="flex flex-col">
                      <div className="text-lg text-deepBlue font-medium">
                        Set up your account
                      </div>
                      <div className="text-gray-500">
                        You’re on a good start!
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-2">
                    <Link
                      to="/personal/pin/setup"
                      className={`flex gap-2 items-center ${
                        !data.data.onboarding.setup_trans_pin
                          ? "text-visaro-orange"
                          : "text-gray-500 line-through font-normal"
                      } text-lg font-medium`}
                    >
                      {!data.data.onboarding.setup_trans_pin ? (
                        <WarningCircle size={22} weight="fill" />
                      ) : (
                        <CheckCircle size={22} weight="fill" />
                      )}
                      <span>Setup Transaction Pin</span>
                    </Link>
                    <div
                      className={`flex gap-2 items-center ${
                        !data.data.onboarding.phone_no_verification
                          ? "text-visaro-orange"
                          : "text-gray-500 line-through font-normal"
                      } text-lg font-medium`}
                    >
                      {!data.data.onboarding.phone_no_verification ? (
                        <WarningCircle size={22} weight="fill" />
                      ) : (
                        <CheckCircle size={22} weight="fill" />
                      )}
                      <span>Verify Phone Number</span>
                    </div>
                    <div
                      className={`flex gap-2 items-center ${
                        !data.data.onboarding.bvn_verification
                          ? "text-visaro-orange"
                          : "text-gray-500 line-through font-normal"
                      } text-lg font-medium`}
                    >
                      {!data.data.onboarding.bvn_verification ? (
                        <WarningCircle size={22} weight="fill" />
                      ) : (
                        <CheckCircle size={22} weight="fill" />
                      )}
                      <span>Verify BVN</span>
                    </div>
                    <div
                      className={`flex gap-2 items-center ${
                        !data.data.onboarding.profile_picture_upload
                          ? "text-visaro-orange"
                          : "text-gray-500 line-through font-normal"
                      } text-lg font-medium`}
                    >
                      {!data.data.onboarding.profile_picture_upload ? (
                        <WarningCircle size={22} weight="fill" />
                      ) : (
                        <CheckCircle size={22} weight="fill" />
                      )}
                      <span>Upload Profile Picture</span>
                    </div>

                    <div
                      className={`flex gap-2 items-center ${
                        data.data.onboarding.account_type === "Not Set"
                          ? "text-visaro-orange"
                          : "text-gray-500 line-through font-normal"
                      } text-lg font-medium`}
                    >
                      {data.data.onboarding.account_type === "Not Set" ? (
                        <WarningCircle size={22} weight="fill" />
                      ) : (
                        <CheckCircle size={22} weight="fill" />
                      )}
                      <span>Setup Account</span>
                    </div>
                  </div>
                </div>
              )
            )}
          </Fragment>
          <Link
            to="/personal/paybills"
            className="col-span-full bg-white drop-shadow-sm p-5 rounded-xl flex gap-5 items-center"
          >
            <img src={visaro_pay_apps} alt="Visaro's Logo" />
            <div className="flex-1">
              <div className="font-medium text-lg">Pay Bills</div>
              <div className="text-gray-500">
                Pay your airtime, data, and electricity bills easily
              </div>
            </div>
            <div className="p-2 bg-gray-100 rounded-full hover:scale-105 transition-all cursor-pointer">
              <ArrowRight weight="bold" size={18} />
            </div>
          </Link>
          {/* Cards */}
          <Fragment>
            {isLoading ? (
              <div className="col-span-1 bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-5">
                <div className="c-shimmer-loading c-shimmer-loading__text"></div>
                <div className="c-shimmer-loading c-shimmer-loading__text c-shimmer-loading__longText"></div>
                <div className="c-shimmer-loading c-shimmer-loading__text"></div>
              </div>
            ) : (
              <div className="col-span-full bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-5">
                <div className="flex justify-between items-center">
                  <div className="text-lg">Cards</div>
                  <DotsThreeVertical size={18} weight="bold" />
                </div>
                <div className="flex justify-between items-center">
                  <Link
                    to="/personal/wallet"
                    className="text-blue-600 font-medium opacity-80 hover:opacity-100 transition"
                  >
                    Link a Card
                  </Link>
                </div>
              </div>
            )}
          </Fragment>
          {/* Send To */}
          <Fragment>
            {isLoading ? (
              <div className="col-span-1 bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-5">
                <div className="c-shimmer-loading c-shimmer-loading__text"></div>
                <div className="c-shimmer-loading c-shimmer-loading__text c-shimmer-loading__longText"></div>
                <div className="c-shimmer-loading c-shimmer-loading__text"></div>
              </div>
            ) : (
              <div className="col-span-1 bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-5 w-full">
                <div className="flex justify-between items-center">
                  <div className="text-lg">BNPL Services</div>
                </div>
                <div className="overflow-x-auto w-full  py-4 custom-scroll-bar">
                  <div className="inline-flex flex-col items-center gap-2 mr-4">
                    <img
                      src="https://nigerialogos.com/logos/buycoins/buycoins.svg"
                      alt="logo"
                      className="w-16 rounded-full aspect-square object-cover bg-gray-100"
                    />
                    <div>Buy Coins</div>
                  </div>
                  <div className="inline-flex flex-col items-center gap-2 mr-4">
                    <img
                      src="https://nigerialogos.com/logos/buycoins/buycoins.svg"
                      alt="logo"
                      className="w-16 rounded-full aspect-square object-cover bg-gray-100"
                    />
                    <div>Buy Coins</div>
                  </div>
                  <div className="inline-flex flex-col items-center gap-2 mr-4">
                    <img
                      src="https://nigerialogos.com/logos/buycoins/buycoins.svg"
                      alt="logo"
                      className="w-16 rounded-full aspect-square object-cover bg-gray-100"
                    />
                    <div>Buy Coins</div>
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
