import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/images/visaro-logo.svg";
import { useEffect, useRef, useState } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import { CheckCircle, CloudArrowUp, FolderPlus, User } from "phosphor-react";
import { HashLoader } from "react-spinners";
import doneImg from "../../../assets/images/successfully-done.svg";
import useCompanyProfileUpdate from "../../../hooks/useCompanyProfileUpdate";
import { shortenFileName } from "../../../helpers/files";

const schema = yup.object().shape({
  certificate_of_incoporation: yup
    .mixed()
    .required("Certificate of incorporation is required"),

  memart: yup.mixed().required("Memart is required"),
  acfta_certificate: yup.mixed().required("ACFTA certificate is required"),
});

const AttachDocuments = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const certRef = useRef<HTMLInputElement>(null);
  const memartRef = useRef<HTMLInputElement>(null);
  const acftaRef = useRef<HTMLInputElement>(null);

  const [completed, setCompleted] = useState(false);

  const {
    companyProfileUpdateError,
    updateCompanyProfile,
    updatingCompanyProfile,
    isCompanyProfileUpdateError,
  } = useCompanyProfileUpdate();

  const { state } = useLocation();

  return (
    <div>
      {completed && (
        <div className="fixed top-0 left-0 h-screen w-screen justify-center bg-white backdrop-blur-sm z-50">
          <nav className="p-4 md:px-10 flex items-center justify-center border border-gray-100">
            <div className="max-w-3xl w-full flex items-center justify-between">
              <Link to="/">
                <img src={logo} alt="Visaro's Logo" />
              </Link>
              <div></div>
            </div>
          </nav>
          <div className="flex items-center justify-center bg-white backdrop-blur-sm p-20 px-4 md:px-10">
            <div className="flex items-center flex-col gap-10">
              <div>
                <img src={doneImg} alt="done" />
              </div>
              <div className="flex flex-col justify-center items-center gap-2">
                <div className="text-4xl md:text-3xl lg:text-5xl max-w-3xl font-bold text-center">
                  Congratulation! Continue With Visaro. You’re all set!
                </div>
                <div className="flex flex-col gap-2">
                  <a
                    href={`/business`}
                    type="submit"
                    className="p-4 px-5 w-full text-center border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
                  >
                    Continue
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {updatingCompanyProfile && (
        <div className="fixed top-0 z-50 left-0 h-screen w-screen flex items-center justify-center bg-black/20 backdrop-blur-sm">
          <HashLoader />
        </div>
      )}
      <Formik
        initialValues={{
          certificate_of_incoporation: null,
          memart: null,
          acfta_certificate: null,
        }}
        validationSchema={schema}
        onSubmit={(values, { resetForm }) => {
          updateCompanyProfile(
            {
              business_name: state?.values?.business_name,
              business_email: state?.values?.business_name,
              business_address: state?.values?.business_address,
              country: state?.values?.country_of_origin,
              region: state?.values?.port_of_origin,
              ...values,
            },
            {
              onSuccess() {
                setCompleted(true);
                resetForm();
              },
            }
          );
        }}
      >
        {({ isValid, dirty, handleChange, handleBlur }) => {
          return (
            <div>
              <nav className="p-4 md:px-10 flex items-center justify-center border border-gray-100">
                <div className="max-w-3xl w-full flex items-center justify-center">
                  <Link to="/">
                    <img src={logo} alt="Visaro's Logo" />
                  </Link>
                </div>
              </nav>
              <div className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg pt-10">
                <div className="max-w-4xl w-full">
                  <div className="flex items-center gap-2">
                    <div className="bg-visaro-orange p-2 rounded-full">
                      <User className="text-white" />
                    </div>
                    <hr className="flex-1 border-visaro-orange border" />
                    <div className="bg-visaro-orange p-2 rounded-full text-white">
                      <FolderPlus />
                    </div>
                    <hr className="flex-1 border border-gray-200" />
                    <div className="bg-gray-100 text-gray-500 p-2 rounded-full">
                      <CheckCircle />
                    </div>
                  </div>
                </div>
                <div className="flex items-center flex-col gap-2">
                  <div className="text-xl max-w-3xl font-medium text-center">
                    Upload and attach documents
                  </div>
                  <div className="text-gray-500 text-center max-w-lg">
                    Kindly upload and attach documents for your business
                    verification
                  </div>
                </div>
                <div className="bg-gray-100 text-center p-4 rounded-full px-6 text-sm">
                  Upload your Certificate of incorporation, Memart and ACFTA
                  certificate
                </div>
                <Form className="w-full flex-col gap-5 flex items-center justify-center text-base">
                  <div className="flex gap-10 flex-col md:flex-row items-stretch max-w-5xl w-full">
                    <div
                      onClick={() => certRef.current?.click()}
                      className="border w-full max-w-4xl rounded-xl border-dashed border-gray-300 p-10 flex items-center justify-center flex-col gap-5 hover:bg-gray-50 cursor-pointer transition"
                    >
                      <div className="bg-gray-100 text-gray-700 p-4 rounded-full">
                        <CloudArrowUp size={40} />
                      </div>
                      <div>
                        <input
                          type="file"
                          hidden
                          ref={certRef}
                          onChange={handleChange("certificate_of_incoporation")}
                          onBlur={handleBlur("certificate_of_incoporation")}
                          id="certificate_of_incoporation"
                          accept="image/png, image/jpeg, image/jpg, application/pdf"
                        />
                        <div className="text-center font-medium">
                          Certificate of incorporation
                        </div>
                        <div className="text-center text-gray-500 text-sm">
                          {certRef.current?.files &&
                          certRef.current?.files[0] ? (
                            <div className="text-center">
                              {shortenFileName(certRef.current.files[0].name)}
                            </div>
                          ) : (
                            <div>
                              <span className="font-medium text-gray-950 underline">
                                Click to upload
                              </span>{" "}
                              or drag and drop <br />
                              PNG,JPG or PDF (max 10mb)
                            </div>
                          )}
                        </div>
                      </div>
                      <ErrorMessage
                        name="certificate_of_incoporation"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div
                      onClick={() => memartRef.current?.click()}
                      className="border w-full max-w-4xl rounded-xl border-dashed border-gray-300 p-10 flex items-center justify-center flex-col gap-5 hover:bg-gray-50 cursor-pointer transition"
                    >
                      <div className="bg-gray-100 text-gray-700 p-4 rounded-full">
                        <CloudArrowUp size={40} />
                      </div>
                      <div>
                        <input
                          type="file"
                          hidden
                          ref={memartRef}
                          accept="image/png, image/jpeg, image/jpg, application/pdf"
                          onChange={handleChange("memart")}
                          onBlur={handleBlur("memart")}
                        />
                        <div className="text-center font-medium">Memart</div>
                        <div className="text-center text-gray-500 text-sm">
                          {memartRef.current?.files &&
                          memartRef.current?.files[0] ? (
                            <div className="text-center">
                              {shortenFileName(memartRef.current.files[0].name)}
                            </div>
                          ) : (
                            <div>
                              <span className="font-medium text-gray-950 underline">
                                Click to upload
                              </span>{" "}
                              or drag and drop <br />
                              PNG,JPG or PDF (max 10mb)
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={() => acftaRef.current?.click()}
                      className="border w-full max-w-4xl rounded-xl border-dashed border-gray-300 p-10 flex items-center justify-center flex-col gap-5 hover:bg-gray-50 cursor-pointer transition"
                    >
                      <div className="bg-gray-100 text-gray-700 p-4 rounded-full">
                        <CloudArrowUp size={40} />
                      </div>
                      <div>
                        <input
                          type="file"
                          hidden
                          ref={acftaRef}
                          id="acfta_certificate"
                          onChange={handleChange("acfta_certificate")}
                          onBlur={handleBlur("acfta_certificate")}
                          accept="image/png, image/jpeg, image/jpg, application/pdf"
                        />
                        <div className="text-center font-medium">
                          ACFTA certificate
                        </div>
                        <div className="text-center text-gray-500 text-sm">
                          {acftaRef.current?.files &&
                          acftaRef.current?.files[0] ? (
                            <div className="text-center">
                              {shortenFileName(acftaRef.current.files[0].name)}
                            </div>
                          ) : (
                            <div>
                              <span className="font-medium text-gray-950 underline">
                                Click to upload
                              </span>{" "}
                              or drag and drop <br />
                              PNG,JPG or PDF (max 10mb)
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div></div>
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    {isCompanyProfileUpdateError && (
                      <div className="text-red-500  flex text-center">
                        {companyProfileUpdateError?.message}
                      </div>
                    )}
                    <button
                      disabled={!isValid || !dirty}
                      type="submit"
                      className="p-4 px-5 w-full border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
                    >
                      Upload
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default AttachDocuments;
