import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/visaro-logo.svg";
import { useContext, useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import { HashLoader } from "react-spinners";
import PasswordCheck from "../../components/PasswordCheck";
import { SignUpContext } from "../SignUp";
import useRegister from "../../hooks/useRegister";

const schema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required")
    .matches(/^(?=.*[A-Z])(?=.*[!@#$%&*?]).*$/, "Password strength is low"),
});

const PersonalInfo = () => {
  const { accountType } = useContext(SignUpContext);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const { register, registerError, registering, isRegisterError } =
    useRegister();

  const navigate = useNavigate();

  const [passwordIsVisible, setPasswordIsVisible] = useState(false);

  return (
    <div>
      {registering && (
        <div className="fixed top-0 z-50 left-0 h-screen w-screen flex items-center justify-center bg-black/20 backdrop-blur-sm">
          <HashLoader />
        </div>
      )}
      <Formik
        initialValues={{
          name: "",
          email: "",
          phoneNumber: "",
          dateOfBirth: "",
          password: "",
        }}
        validationSchema={schema}
        onSubmit={(values, { resetForm }) => {
          register(values, {
            onSuccess(data) {
              localStorage.setItem("token", data.token);
              navigate(
                `/otp?email=${values.email}&redirect=${
                  accountType === "business"
                    ? "sign-up/business/select-business-type"
                    : "sign-up/personal/bvn-verification"
                }`
              );
              resetForm();
            },
          });
        }}
      >
        {({ isValid, dirty, values, setTouched }) => {
          return (
            <div>
              <nav className="p-4 md:px-10 flex items-center justify-center border border-gray-100">
                <div className="max-w-3xl w-full flex items-center justify-between">
                  <Link to="/">
                    <img src={logo} alt="Visaro's Logo" />
                  </Link>
                  <div></div>
                  <div className="hidden md:flex items-center gap-4">
                    <div></div>
                    <Link
                      to="/login"
                      className="p-3 px-5 border-deepBlue border rounded-lg font-bold text-sm text-deepBlue"
                    >
                      Log in
                    </Link>
                  </div>
                </div>
              </nav>
              <div className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg">
                <div className="flex flex-col justify-center items-center gap-2">
                  <div className="text-4xl md:text-3xl lg:text-5xl max-w-3xl font-bold text-center">
                    Sign up for Visaro
                  </div>
                  <div className="text-gray-500 text-center max-w-lg">
                    This info helps you log in and keeps your account secure.
                  </div>
                </div>
                <Form className="w-full flex-col gap-5 flex items-center justify-center text-base">
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <label
                      htmlFor="firstName"
                      className="text-sm font-bold text-gray-700"
                    >
                      First Name*
                    </label>
                    <Field
                      type="text"
                      placeholder="Enter your first name"
                      name="firstName"
                      id="firstName"
                      className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                    />
                  </div>
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <label
                      htmlFor="lastName"
                      className="text-sm font-bold text-gray-700"
                    >
                      Last Name*
                    </label>
                    <Field
                      type="text"
                      placeholder="Enter your last name"
                      name="lastName"
                      id="lastName"
                      className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                    />
                  </div>

                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <label
                      htmlFor="email"
                      className="text-sm font-bold text-gray-700"
                    >
                      Email*
                    </label>
                    <Field
                      type="email"
                      placeholder="Enter your email"
                      name="email"
                      id="email"
                      className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500"
                    />
                    {registerError?.validationErrors?.email && (
                      <div className="text-red-500">
                        {registerError?.validationErrors?.email}
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <label
                      htmlFor="password"
                      className="text-sm font-bold text-gray-700"
                    >
                      Password*
                    </label>
                    <div className="rounded-lg border border-gray-300 shadow flex items-center w-full relative overflow-hidden focus-within:outline-deepBlue focus-within:outline accent-deepBlue">
                      <Field
                        onFocus={() =>
                          setTouched(
                            {
                              password: true,
                            },
                            false
                          )
                        }
                        autoComplete="off"
                        type={passwordIsVisible ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder="Create a password"
                        className="px-4 py-3 bg-transparent flex-1 rounded-lg focus:outline-none"
                      />
                      <div
                        onClick={() => setPasswordIsVisible((prev) => !prev)}
                        className="absolute right-0 m-4 cursor-pointer text-sm"
                      >
                        {passwordIsVisible ? "Hide" : "Show"}
                      </div>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500"
                    />
                    {registerError?.validationErrors?.password && (
                      <div className="text-red-500">
                        {registerError?.validationErrors?.password}
                      </div>
                    )}
                    <PasswordCheck password={values.password} />
                  </div>
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    {isRegisterError && (
                      <div className="text-red-500 text-left flex">
                        {registerError?.message}
                      </div>
                    )}
                    <button
                      disabled={!isValid || !dirty}
                      type="submit"
                      className="p-4 px-5 w-full border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
                    >
                      {registering ? "Loading" : "Sign Up"}
                    </button>
                  </div>
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <button
                      type="button"
                      className="p-4 px-5 w-full border-gray-300 text-gray-700 border rounded-lg font-bold text-sm flex gap-4 items-center justify-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 186.69 190.5"
                        className="w-6 h-6"
                      >
                        <g transform="translate(1184.583 765.171)">
                          <path
                            clipPath="none"
                            mask="none"
                            d="M-1089.333-687.239v36.888h51.262c-2.251 11.863-9.006 21.908-19.137 28.662l30.913 23.986c18.011-16.625 28.402-41.044 28.402-70.052 0-6.754-.606-13.249-1.732-19.483z"
                            fill="#4285f4"
                          />
                          <path
                            clipPath="none"
                            mask="none"
                            d="M-1142.714-651.791l-6.972 5.337-24.679 19.223h0c15.673 31.086 47.796 52.561 85.03 52.561 25.717 0 47.278-8.486 63.038-23.033l-30.913-23.986c-8.486 5.715-19.31 9.179-32.125 9.179-24.765 0-45.806-16.712-53.34-39.226z"
                            fill="#34a853"
                          />
                          <path
                            clipPath="none"
                            mask="none"
                            d="M-1174.365-712.61c-6.494 12.815-10.217 27.276-10.217 42.689s3.723 29.874 10.217 42.689c0 .086 31.693-24.592 31.693-24.592-1.905-5.715-3.031-11.776-3.031-18.098s1.126-12.383 3.031-18.098z"
                            fill="#fbbc05"
                          />
                          <path
                            d="M-1089.333-727.244c14.028 0 26.497 4.849 36.455 14.201l27.276-27.276c-16.539-15.413-38.013-24.852-63.731-24.852-37.234 0-69.359 21.388-85.032 52.561l31.692 24.592c7.533-22.514 28.575-39.226 53.34-39.226z"
                            fill="#ea4335"
                            clipPath="none"
                            mask="none"
                          />
                        </g>
                      </svg>

                      <span>Sign Up With Google</span>
                    </button>
                  </div>
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <Link to="/login" className="text-center text-gray-600">
                      Already have an account?{" "}
                      <span className="text-visaro-orange">Log in</span>
                    </Link>
                  </div>
                </Form>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default PersonalInfo;
