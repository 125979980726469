import React, { useState, ChangeEvent, FormEvent } from 'react';
import logo from "../../assets/images/visaro-logo.svg";
import { CheckCircle, Circle, RadioButton } from 'phosphor-react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/signupmodal.css'


const SignUpForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    phoneNumber: '',
  });

  const navigate = useNavigate();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Handle form submission logic here

    // Navigate to OTP page after form submission
    navigate('/otp');
  };

  return (
    <div className="sign-up-form">
      <img src={logo} alt="Visaro's Logo" />
      <h2>Welcome to Visaro</h2>
      <p>Enter your details to start using Visaro</p>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email"
          required
        />
        <input
          type="tel"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          placeholder="Phone Number"
          required
        />
        <button type="submit">Sign Up</button>
      </form>
      <p>Or</p>
      <div className="alternative-link">
        <Link to="/login">Login</Link>
      </div>
    </div>
  );
};



const SelectAccount = () => {
  const [accountType, setAccountType] = useState('personal');
  const [showModal, setShowModal] = useState(false);

  const handleGetStarted = () => {
    setShowModal(true);
  };

  return (
    <div>
      <div className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg">
        <div className="flex items-center">Select an account Type</div>
        <div className="text-5xl md:text-5xl lg:text-7xl max-w-3xl font-bold text-center">
          How do you want to use Visaro?
        </div>
        <div className="text-gray-500 text-center max-w-lg">
          Choose the type of account you want to create with Visaro
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-20 py-5">
          {/* Personal Account */}
          <div
            className="flex flex-col gap-5 border border-gray-300 p-10 rounded-2xl shadow-xl"
            onClick={() => setAccountType('personal')}
          >
            {/* Radio button or circle */}
            <div>
              {accountType === 'personal' ? (
                <RadioButton weight="fill" className="text-deepBlue" size={40} strokeWidth={1} />
              ) : (
                <Circle weight="regular" className="text-gray-300" size={40} strokeWidth={1} />
              )}
            </div>
            <div className="text-4xl font-semibold">Personal</div>
            <div className="text-gray-500">Send, spend, and manage your money. All from one app.</div>
            <div className="flex flex-col gap-5">
              <div className="flex items-center gap-5">
                <CheckCircle weight="fill" size={32} />
                <span className="text-gray-500">Manage money, pay bills, and more.</span>
              </div>
              <div className="flex items-center gap-5">
                <CheckCircle weight="fill" size={32} />
                <span className="text-gray-500">Shop online without sharing your info.</span>
              </div>
              <div className="flex items-center gap-5">
                <CheckCircle weight="fill" size={32} />
                <span className="text-gray-500">Own products/services instantly while you spread payments.</span>
              </div>
            </div>
          </div>

          {/* Business Account */}
          <div
            className="flex flex-col gap-5 border border-gray-300 p-10 rounded-2xl shadow-xl"
            onClick={() => setAccountType('business')}
          >
            {/* Radio button or circle */}
            <div>
              {accountType === 'business' ? (
                <RadioButton weight="fill" className="text-deepBlue" size={40} strokeWidth={1} />
              ) : (
                <Circle weight="regular" className="text-gray-300" size={40} strokeWidth={1} />
              )}
            </div>
            <div className="text-4xl font-semibold">Business</div>
            <div className="text-gray-500">Get paid online, in-store, or on the go. Help grow your business.</div>
            <div className="flex flex-col gap-5">
              <div className="flex items-center gap-5">
                <CheckCircle weight="fill" size={32} />
                <span className="text-gray-500">Accept many forms of payment so you don’t miss a sale.</span>
              </div>
              <div className="flex items-center gap-5">
                <CheckCircle weight="fill" size={32} />
                <span className="text-gray-500">Easily integrate with tools to help you run your business.</span>
              </div>
              <div className="flex items-center gap-5">
                <CheckCircle weight="fill" size={32} />
                <span className="text-gray-500">Put your business in front of our ready-to-buy user base.</span>
              </div>
            </div>
          </div>
        </div>

        {/* Get Started Button */}
        <div className="flex items-center gap-2">
          <button
            onClick={handleGetStarted}
            className="p-4 px-6 border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white"
          >
            Get Started
          </button>
        </div>

        {/* Modal */}
        {showModal && (
        <div className="custom-modal">
          <div className="custom-modal-dialog">
            <div className="custom-modal-content">
              <div className="modal-header">
                <button type="button" className="close" onClick={() => setShowModal(false)}>
                  <span>&times;</span>
                </button>
              </div>
              <div className="custom-modal-body">
                <SignUpForm />
                {/* Optionally, display a message based on the selected account type */}
                <p>Thank you for choosing {accountType === "personal" ? "Personal" : "Business"} account.</p>
              </div>
            </div>
          </div>
        </div>
      )}
      </div>
    </div>
  );
};

export default SelectAccount;
