import { Check } from "phosphor-react";

const CustomersListItem = () => {
  return (
    <div
      className={`grid grid-cols-7 hover:brightness-95 bg-white transition items-center border-b border-gray-200`}
    >
      <div className="py-4 font-medium text-gray-700 px-8 flex gap-4 items-center">
        <div className="bg-green-100 w-max px-3 py-1 rounded-full flex items-center gap-1 text-green-700 font-medium">
          <Check weight="bold" />
          <span>Online</span>
        </div>
      </div>
      <div className="py-4 px-8 flex gap-2 col-span-2">
        <div className="w-10 h-10 aspect-square grid font-medium place-items-center rounded-full bg-visaro-primary-700/10 relative">
          <span>OR</span>
          <img
            src={require("../assets/images/Avatar-1.png")}
            alt="customer"
            className="w-10 h-10 rounded-full object-cover absolute top-0 left-0"
          />
        </div>
        <div>
          <div className="font-medium text-gray-600">Olivia Rhey</div>
          <div>olivia@visaro.com</div>
        </div>
      </div>
      <div className="py-4 px-8 col-span-2">No Phone Number</div>
      <div className="py-4 px-8 col-span-2">Jan 6, 2020</div>
    </div>
  );
};

export default CustomersListItem;
