import {
  FC,
  createContext,
  PropsWithChildren,
  useState,
  useContext,
} from "react";
import { User } from "../@types";
import { useQuery } from "react-query";
import { API_URL } from "../../config";
import { get } from "../helpers/api";

export const Auth = createContext<{
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
}>({
  user: null,
  setUser() {
    // do nothing
  },
});

export const useAuth = () => {
  const context = useContext(Auth);

  return context;
};

const AuthContext: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);

  useQuery(
    "profile",
    () =>
      get(API_URL + "/profile_details", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
    {
      onSuccess(data) {
        console.log(data);
        setUser(data.data);
      },
    }
  );

  return (
    <Auth.Provider
      value={{
        user,
        setUser,
      }}
    >
      {children}
    </Auth.Provider>
  );
};

export default AuthContext;
