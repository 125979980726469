import React from "react";
import { Link } from "react-router-dom";

const NewletterForm = () => {
  return (
    <section className="p-20 px-5 md:px-20 gap-10 flex flex-col items-center text-lg bg-gray-100">
      <div className="flex flex-col items-center text-center gap-5">
        <div className="font-bold text-3xl">Sign up for our newsletter</div>
        <div className="text-gray-500">
          Be the first to know about releases and industry news and insights.
        </div>
      </div>
      <form className="flex flex-col max-w-xl w-full">
        <div className="flex flex-col md:flex-row gap-4 items-center">
          <input
            type="email"
            placeholder="Enter your email"
            className="border border-gray-300 rounded-lg flex items-center flex-1 p-3 px-4 focus:outline-none "
          />
          <button
            type="submit"
            className="p-3 w-full md:w-fit px-8 rounded-lg font-bold bg-deepBlue text-white"
          >
            Subscribe
          </button>
        </div>
        <div className="text-sm text-gray-500 py-2">
          We care about your data in our{" "}
          <Link to="/privacy" className="underline">
            privacy policy
          </Link>
        </div>
      </form>
    </section>
  );
};

export default NewletterForm;
