import { QueryObserverOptions, useQuery } from "react-query";
import { APIResponse } from "../@types";
import { APIError, get } from "../helpers/api";
import { API_URL } from "../../config";

function useResendOTP(
  otpType: string,
  options?: QueryObserverOptions<APIResponse<any>, APIError>
) {
  const {
    isLoading: resendingOTP,
    isError: isResendError,
    error: resendError,
    refetch: resendOTP,
  } = useQuery<APIResponse<any>, APIError>(
    "resend_otp",
    () =>
      get(API_URL + `/resend_otp/${otpType}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
    {
      enabled: false,
      ...options,
    }
  );

  return {
    resendOTP,
    resendingOTP,
    isResendError,
    resendError,
  };
}

export default useResendOTP;
