import { MutationOptions, useMutation } from "react-query";
import { APIError, post } from "../helpers/api";
import { API_URL } from "../../config";
import { APIResponse } from "../@types";

function useValidateOTP<T>(
  options?: MutationOptions<APIResponse<T>, APIError, Record<any, any>>
) {
  const {
    isLoading: validatingOTP,
    isError: isOTPError,
    error: otpError,
    mutate: validateOTP,
  } = useMutation((variables: Record<any, any>) => {
    return post(API_URL + "/validate_otp", variables, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }, options);

  return {
    validateOTP,
    isOTPError,
    otpError,
    validatingOTP,
  };
}

export default useValidateOTP;
