import { ArrowDown, ArrowUpRight, MagnifyingGlass } from "phosphor-react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import moment from "moment";
import { useEffect } from "react";

const blogs: {
  title: string;
  previewText: string;
  category: string;
  author: {
    name: string;
  };
  datePosted: Date;
}[] = [
  {
    title: "UX review presentations",
    previewText:
      "How do you create compelling presentations that wow your colleagues and impress your managers?",
    category: "Design",
    author: {
      name: "Olivia Rhye",
    },
    datePosted: new Date(),
  },
  {
    title: "Migrating to Linear 101",
    previewText:
      "How do you create compelling presentations that wow your colleagues and impress your managers?",
    category: "Product",
    author: {
      name: "Olivia Rhye",
    },
    datePosted: new Date(),
  },

  {
    title: "Building your API Stack",
    previewText:
      "How do you create compelling presentations that wow your colleagues and impress your managers?",
    category: "Software Engineering",
    author: {
      name: "Olivia Rhye",
    },
    datePosted: new Date(),
  },
  {
    title: "UX review presentations",
    previewText:
      "How do you create compelling presentations that wow your colleagues and impress your managers?",
    category: "Design",
    author: {
      name: "Olivia Rhye",
    },
    datePosted: new Date(),
  },
  {
    title: "UX review presentations",
    previewText:
      "How do you create compelling presentations that wow your colleagues and impress your managers?",
    category: "Design",
    author: {
      name: "Olivia Rhye",
    },
    datePosted: new Date(),
  },
  {
    title: "UX review presentations",
    previewText:
      "How do you create compelling presentations that wow your colleagues and impress your managers?",
    category: "Design",
    author: {
      name: "Olivia Rhye",
    },
    datePosted: new Date(),
  },
];

const Blog = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Navbar />
      <header className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg">
        <div className="flex flex-col gap-5 text-center items-center">
          <div className="bg-visaro-primary-700/10 p-2 px-4 rounded-full text-sm font-medium">
            Our blog
          </div>
          <div className="text-4xl md:text-4xl lg:text-6xl max-w-5xl font-bold text-center">
            Resources and insights
          </div>
        </div>
        <div className="text-gray-500 text-center">
          The latest industry news, interviews, technologies, and resources.
        </div>
        <form className="flex flex-col gap-8">
          <div className="border border-gray-300 rounded-lg flex items-center px-4">
            <MagnifyingGlass />
            <input
              className="flex-1 p-4 py-3 focus:outline-none bg-transparent"
              type="text"
              placeholder="Search"
            />
          </div>
        </form>
      </header>
      <section className="p-20 px-5 md:px-20 gap-20 flex flex-col text-lg ">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
          {blogs.map((blog, key) => {
            return (
              <div
                className="bg-white drop-shadow-sm p-5 rounded-lg flex flex-col gap-5"
                key={key}
              >
                <img src="" alt="" className="aspect-video bg-gray-100" />
                <div className="flex flex-col gap-5">
                  <div className="text-visaro-primary-700 font-medium">
                    {blog.category}
                  </div>
                  <div className="flex items-start justify-between">
                    <div className="text-2xl font-semibold">{blog.title}</div>
                    <ArrowUpRight weight="bold" size={20} />
                  </div>
                  <div className="text-gray-500">{blog.previewText}</div>
                </div>
                <div className="flex gap-5 items-center pt-10">
                  <div>
                    <img
                      src={require("../assets/images/Avatar-2.png")}
                      alt="author"
                      className="aspect-square w-12 bg-gray-100 rounded-full"
                    />
                  </div>
                  <div className="flex flex-col ">
                    <div>{blog.author.name}</div>
                    <div className="text-sm text-gray-500">
                      {moment(blog.datePosted).calendar()}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex items-center justify-center">
          <div className="bg-visaro-primary-700/10 rounded-md px-4 py-3 cursor-pointer text-visaro-primary-700 font-semibold text-base flex items-center gap-2">
            <ArrowDown weight="bold" size={18} />
            <span>Load More</span>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Blog;
