import TransactionsList from "../../../components/TransactionsList";

const Transactions = () => {
  return (
    <div>
      <TransactionsList />
    </div>
  );
};

export default Transactions;
