import {
  Link,
  ScrollRestoration,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import logo from "../assets/images/visaro-logo.svg";
import { useCallback, useMemo, useState } from "react";
import { EnvelopeSimple } from "phosphor-react";
import { HashLoader } from "react-spinners";
import OTPInput from "../components/OTPInput";
import useResendOTP from "../hooks/useResendOtp";
import useValidateOTP from "../hooks/useValidateOtp";
import { OTP_TYPES } from "../@types";

const ValidateOTP = () => {
  const [search] = useSearchParams();

  const email = search.get("email");
  const redirect = search.get("redirect");
  const type = search.get("type");
  const autoSendOtp = search.get("auto");

  const [otp, setOTP] = useState("");

  const isValid = useMemo(() => {
    return (
      otp.length === 4 &&
      otp.split("").every((v) => v !== "" && !isNaN(Number(v)))
    );
  }, [otp]);

  const { validateOTP, validatingOTP, isOTPError, otpError } = useValidateOTP({
    onSuccess(data) {
      navigate(`/${redirect}?otp=true`);
    },
  });

  const { isResendError, resendingOTP, resendError, resendOTP } = useResendOTP(
    type || OTP_TYPES.LoginVerification,
    {
      enabled: autoSendOtp === "true",
    }
  );

  const isError = useMemo(
    () => isOTPError || isResendError,
    [isOTPError, isResendError]
  );

  const error = useMemo(() => otpError || resendError, [otpError, resendError]);

  const isLoading = useMemo(
    () => validatingOTP || resendingOTP,
    [validatingOTP, resendingOTP]
  );

  const navigate = useNavigate();

  const handleResend = useCallback(() => {
    resendOTP();
  }, [resendOTP]);

  // const handleSubmit = () => {
  //   validateOTP({
  //     otp_code: otp,
  //   });
  // };
  const handleSubmit = () => {
    navigate('/userland')
  };
  return (
    <div>
      <ScrollRestoration />
      {isLoading && (
        <div className="fixed top-0 z-50 left-0 h-screen w-screen flex items-center justify-center bg-black/20 backdrop-blur-sm">
          <HashLoader />
        </div>
      )}
      <div>
        <nav className="p-4 md:px-10 flex items-center justify-center border border-gray-100">
          <div className="max-w-3xl w-full flex items-center justify-between">
            <Link to="/">
              <img src={logo} alt="Visaro's Logo" />
            </Link>
            <div></div>
            <div className="hidden md:flex items-center gap-4">
              <div></div>
            </div>
          </div>
        </nav>
        <div className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg">
          <div className="p-5 bg-gray-100 rounded-full">
            <EnvelopeSimple size={30} />
          </div>
          <div>
            <div className="font-medium text-center max-w-lg">
              Please check your email.
            </div>
            <div className="text-gray-500 text-center max-w-lg">
              {email
                ? `We've sent a code to ${email}`
                : "We've sent a code to your email"}
            </div>
          </div>
          <div className="w-full flex-col gap-5 flex items-center justify-center text-base">
            <OTPInput onChange={(otp) => setOTP(otp)} length={4} />
            <div className="text-gray-500">
              Didn’t get a code?{" "}
              <span className="underline" onClick={handleResend}>
                Click to resend.
              </span>
            </div>
            <div className="flex flex-col gap-2 max-w-md w-full">
              {isError && (
                <div className="text-red-500 text-left flex">
                  {error?.message}
                </div>
              )}
              <button
                disabled={!isValid}
                onClick={handleSubmit}
                className="p-4 px-5 w-full border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
              >
                Confirm
              </button>
            </div>
            <div className="flex flex-col gap-2 max-w-md w-full">
              <Link to="/login" className="text-center text-gray-600">
                Back to <span className="text-visaro-orange">Log in</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidateOTP;
