import { Route, Routes } from "react-router-dom";
import DashboardNavbar from "../../components/DashboardNavbar";
import Dashboard from "./Dashboard";
import Wallet from "./Wallet";
import Payments from "./Payments";
import Footer from "../../components/Footer";

const Home = () => {
  return (
    <>
      <DashboardNavbar pathPrefix="business" />
      <Routes>
        <Route path="home" element={<Dashboard />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="payments/*" element={<Payments />} />
        <Route path="wallet" element={<Wallet />} />
      </Routes>
      <Footer />
    </>
  );
};

export default Home;
