import React from "react";
import { Link } from "react-router-dom";
import "../styles/BankVerified.css";
import check from "../../assets/images/check.png";
import logo from "../../assets/images/visaro-logo.svg";
import Footer from "../../components/Footer";

const FaceVerifySuccess = () => {
  return (
    <div>
      <nav className="p-4 md:px-10 flex items-center justify-center border border-gray-100">
        <div className="max-w-3xl w-full flex items-center justify-between">
          <Link to="/">
            <img src={logo} alt="Visaro's Logo" />
          </Link>
          <div></div>
          <div className="hidden md:flex items-center gap-4">
            <div></div>
            <Link
              to="/login"
              className="p-3 px-5 border-deepBlue border rounded-lg font-bold text-sm text-deepBlue"
            >
              Log in
            </Link>
          </div>
        </div>
      </nav>
      <div className="bank-verified-container">
        <div className="bank-verified-content">
          <img
            src={check}
            alt=""
            className="bank-verified-img"
          />
          <h2 className="bank-verified-title">Face Recognition complete</h2>
          <p className="bank-verified-text">
            Congrat, your face recognition is complete successfully. This ensure a safe and trustworthy
            while using our platform
          </p>
          <Link to="/personal/home" className="btn-login">
            Go to Dashboard
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FaceVerifySuccess;
