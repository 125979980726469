import { NavLink, Route, Routes } from "react-router-dom";
import Profile from "./settings/Profile";
import Security from "./settings/Security";
import Notification from "./settings/Notification";
import Preferences from "./settings/Preferences";

const Settings = () => {
  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-4 p-10 py-4">
        <NavLink
          to="profile"
          className="p-3 px-5 text-sm rounded-lg font-medium max-w-fit flex items-center gap-2 transition c-nav-link"
        >
          Profile
        </NavLink>
        <NavLink
          to="security"
          className="p-3 px-5 text-sm rounded-lg font-medium max-w-fit flex items-center gap-2 transition c-nav-link"
        >
          Security
        </NavLink>
        <NavLink
          to="notification"
          className="p-3 px-5 text-sm rounded-lg font-medium max-w-fit flex items-center gap-2 transition c-nav-link"
        >
          Notification
        </NavLink>
        {/* <NavLink
          to="verification"
          className="p-3 px-5 text-sm rounded-lg font-medium max-w-fit flex items-center gap-2 transition c-nav-link"
        >
          Verification
        </NavLink> */}
        <NavLink
          to="preferences"
          className="p-3 px-5 text-sm rounded-lg font-medium max-w-fit flex items-center gap-2 transition c-nav-link"
        >
          Preferences
        </NavLink>
        <div className="flex-1"></div>
      </div>
      <Routes>
        <Route path="/" element={<Profile />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/security" element={<Security />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/verification" element={<Profile />} />
        <Route path="/preferences" element={<Preferences />} />
      </Routes>
    </div>
  );
};

export default Settings;
