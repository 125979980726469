import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useEffect } from "react";

const Legal = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Navbar />
      <header className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg bg-[#F9FAFB]">
        <div className="text-4xl md:text-4xl lg:text-6xl max-w-5xl font-bold text-center">
          We care about your privacy
        </div>
        <div className="text-gray-500 text-center">
          Your privacy is important to us at Untitled. We respect your privacy
          regarding any information we may collect from you across our website.
        </div>
        <div className="flex items-center gap-2 bg-gray-300 p-1 rounded-lg">
          <button className="bg-gray-50 p-4 rounded-lg px-6 drop-shadow-md">
            Human Friendly
          </button>
          <button className="p-4 rounded-lg text-gray-500 px-6">
            Legal Nonsense
          </button>
        </div>
      </header>
      <section className="flex flex-col p-32 px-10 md:px-32 gap-10 md:gap-20 items-center text-lg text-gray-500 justify-center">
        <div className="max-w-3xl">
          Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam
          suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis
          montes, sit sit. Tellus aliquam enim urna, etiam. Mauris posuere
          vulputate arcu amet, vitae nisi, tellus tincidunt. At feugiat sapien
          varius id. <br /> <br /> Eget quis mi enim, leo lacinia pharetra,
          semper. Eget in volutpat mollis at volutpat lectus velit, sed auctor.
          Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at.
          Suscipit tristique risus, at donec. In turpis vel et quam imperdiet.
          Ipsum molestie aliquet sodales id est ac volutpat.
        </div>
        <div className="max-w-3xl">
          <h3 className="text-3xl font-bold text-deepBlue">
            What information do we collect?
          </h3>
          <br />
          Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam
          suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis
          montes, sit sit. Tellus aliquam enim urna, etiam. Mauris posuere
          vulputate arcu amet, vitae nisi, tellus tincidunt. At feugiat sapien
          varius id. <br /> <br /> Eget quis mi enim, leo lacinia pharetra,
          semper. Eget in volutpat mollis at volutpat lectus velit, sed auctor.
          Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at.
          Suscipit tristique risus, at donec. In turpis vel et quam imperdiet.
          Ipsum molestie aliquet sodales id est ac volutpat.
        </div>
        <div className="max-w-3xl">
          <h3 className="text-xl font-bold text-black">
            Do we use cookies and other tracking technologies?
          </h3>
          <br />
          Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis
          aliquam ullamcorper porttitor. Pulvinar ullamcorper sit dictumst ut
          eget a, elementum eu. Maecenas est morbi mattis id in ac pellentesque
          ac.
          <br />
          <br />
          <h3 className="text-xl font-bold text-black">
            Do we use cookies and other tracking technologies?
          </h3>
          <br />
          Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis
          aliquam ullamcorper porttitor. Pulvinar ullamcorper sit dictumst ut
          eget a, elementum eu. Maecenas est morbi mattis id in ac pellentesque
          ac.
          <br />
          <br />
          <h3 className="text-xl font-bold text-black">
            How do we keep your information safe?
          </h3>
          <br />
          Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis
          aliquam ullamcorper porttitor. Pulvinar ullamcorper sit dictumst ut
          eget a, elementum eu. Maecenas est morbi mattis id in ac pellentesque
          ac.
          <br />
          <br />
          <h3 className="text-xl font-bold text-black">
            How do we keep your information safe?
          </h3>
          <br />
          Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis
          aliquam ullamcorper porttitor. Pulvinar ullamcorper sit dictumst ut
          eget a, elementum eu. Maecenas est morbi mattis id in ac pellentesque
          ac.
          <br />
          <br />
          <h3 className="text-3xl font-bold text-deepBlue">
            What are your privacy rights?
          </h3>
          <br />
          Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis
          aliquam ullamcorper porttitor. Pulvinar ullamcorper sit dictumst ut
          eget a, elementum eu. Maecenas est morbi mattis id in ac pellentesque
          ac.
          <br />
          <br />
          <h3 className="text-xl font-bold text-black">
            How can you contact us about this policy?
          </h3>
          <br />
          Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis
          aliquam ullamcorper porttitor. Pulvinar ullamcorper sit dictumst ut
          eget a, elementum eu. Maecenas est morbi mattis id in ac pellentesque
          ac. Sagittis et eu at elementum, quis in. Proin praesent volutpat
          egestas sociis sit lorem nunc nunc sit. Eget diam curabitur mi ac.
          Auctor rutrum lacus malesuada massa ornare et. Vulputate consectetur
          ac ultrices at diam dui eget fringilla tincidunt. Arcu sit dignissim
          massa erat cursus vulputate gravida id. Sed quis auctor vulputate hac
          elementum gravida cursus dis.
          <br />
          <br />
          <ol className="list-decimal list-inside">
            <li>Lectus id duis vitae porttitor enim gravida morbi.</li>
            <li>
              Eu turpis posuere semper feugiat volutpat elit, ultrices
              suspendisse. Auctor vel in vitae placerat.
            </li>
            <li>
              Suspendisse maecenas ac donec scelerisque diam sed est duis purus.
            </li>
          </ol>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Legal;
