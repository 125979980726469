import { Check } from "phosphor-react";
import { useState } from "react";

const TransactionsListItem = () => {
  const [checked, setChecked] = useState(false);

  return (
    <div
      className={`grid grid-cols-7 hover:brightness-95 bg-white transition items-center border-b border-gray-200 ${
        checked && "brightness-95"
      }`}
    >
      <div className="py-4 font-medium text-gray-700 px-8 flex gap-4 items-center">
        <input
          type="checkbox"
          onChange={(e) => setChecked(e.target.checked)}
          className="accent-deepBlue w-4 h-4"
        />
        #2020
      </div>

      <div className="py-4 px-8">Jan 6, 2020</div>
      <div className="py-4 px-8">
        <div className="bg-green-100 w-max px-3 py-1 rounded-full flex items-center gap-1 text-green-700 font-medium">
          <Check weight="bold" />
          <span>Paid</span>
        </div>
      </div>
      <div className="py-4 px-8 flex gap-2">
        <div className="w-10 h-10 aspect-square grid font-medium place-items-center rounded-full bg-visaro-primary-700/10 relative">
          <span>OR</span>
          <img
            src={require("../assets/images/Avatar-1.png")}
            alt="customer"
            className="w-10 h-10 rounded-full object-cover absolute top-0 left-0"
          />
        </div>
        <div>
          <div className="font-medium text-gray-600">Olivia Rhey</div>
          <div>olivia@visaro.com</div>
        </div>
      </div>
      <div className="py-4 px-8">Surgery</div>
      <div className="py-4 px-8">70,000.00</div>
      <div className="py-4 flex gap-3 justify-end px-8 font-medium">
        <div>Archive</div>
        <div className="text-visaro-orange">Download</div>
      </div>
    </div>
  );
};

export default TransactionsListItem;
