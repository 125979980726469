import { Route, Routes } from "react-router-dom";
import DashboardNavbar from "../../components/DashboardNavbar";
import Dashboard from "./Dashboard";
import Send from "./Send";
import Activity from "./Activity";
import Wallet from "./Wallet";
import Footer from "../../components/Footer";
import PayBills from "./PayBill";
import Pin from "../general/Pin";
import Settings from "./Settings";
import AviationConfirmBooking from "./AviationConfirmBooking";
import AviationCardPayment from "./AviationCardPayment";
import TripBookingWebView from "../../components/TripBookingWebView";
import MyCart from "./MyCart";

const Home = () => {
  return (
    <>
      <DashboardNavbar pathPrefix="personal" />
      <Routes>
        <Route path="home" element={<Dashboard />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="send/*" element={<Send />} />
        <Route path="activity" element={<Activity />} />
        <Route path="wallet" element={<Wallet />} />
        <Route path="paybills/*" element={<PayBills />} />
        <Route path="pin/*" element={<Pin />} />
        <Route path="settings/*" element={<Settings />} />
        <Route path="aviation/" element={<AviationConfirmBooking />} />
        <Route path="aviation/card/" element={<AviationCardPayment />} />
        <Route path="trip-booking/" element={<TripBookingWebView />} />
        {/* <Route path="cart/" element={<MyCart />} /> */}
      </Routes>
      <Footer />
    </>
  );
};

export default Home;
