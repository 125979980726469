import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/visaro-logo.svg";
import { useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import { BVNData } from "../../../@types";

const schema = yup.object().shape({
  phoneNumber: yup.string().required("Phone number is required"),

  legalFirstName: yup.string().required("Legal first name is required"),
  legalLastName: yup.string().required("Legal last name is required"),

  dateOfBirth: yup.date().required("Date of Birth is required"),
  address: yup.string().required("Address is required"),
});

const CompleteProfile = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const navigate = useNavigate();

  const { state } = useLocation();

  const bvnData = state?.data || ({} as BVNData);

  return (
    <div>
      <Formik
        initialValues={{
          legalFirstName: bvnData?.first_name,
          legalLastName: bvnData?.last_name,
          phoneNumber: bvnData?.phone_1 || bvnData?.phone_2,
          dateOfBirth: "1990-01-01",
          address: bvnData?.address
            ? `${bvnData?.address || ""}, ${
                bvnData.state_of_residence?.description || ""
              }`
            : "",
        }}
        validationSchema={schema}
        onSubmit={(values, { resetForm }) => {
          navigate("/sign-up/personal/upload-profile-image", {
            state: {
              data: {
                account_type: 1,
                phone_1: values.phoneNumber,
                phone_2: bvnData?.phone_2,
                profile_pics: "",
              },
            },
          });
        }}
      >
        {({ isValid }) => {
          return (
            <div>
              <nav className="p-4 md:px-10 flex items-center justify-center border border-gray-100">
                <div className="max-w-3xl w-full flex items-center justify-between">
                  <Link to="/">
                    <img src={logo} alt="Visaro's Logo" />
                  </Link>
                  <div></div>
                </div>
              </nav>
              <div className="flex flex-col justify-center items-center p-20 px-5 md:px-20 gap-8 text-lg">
                <div className="flex flex-col justify-center items-center gap-2">
                  <div className="text-4xl md:text-3xl lg:text-5xl max-w-3xl font-bold text-center">
                    Complete your Profile
                  </div>
                  <div className="text-gray-500 text-center max-w-lg">
                    Kindly confirm the personal details generated from your BVN
                  </div>
                </div>
                <Form className="w-full flex-col gap-5 flex items-center justify-center text-base">
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <label
                      htmlFor="legalFirstName"
                      className="text-sm font-bold text-gray-700"
                    >
                      Legal First Name*
                    </label>
                    <Field
                      disabled
                      type="text"
                      placeholder="Enter your Legal First Name"
                      name="legalFirstName"
                      id="legalFirstName"
                      className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full disabled:text-gray-500"
                    />
                    <ErrorMessage
                      name="legalFirstName"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <label
                      htmlFor="legalLastName"
                      className="text-sm font-bold text-gray-700"
                    >
                      Legal Last Name*
                    </label>
                    <Field
                      disabled
                      type="text"
                      placeholder="Enter your Legal Last Name"
                      name="legalLastName"
                      id="legalLastName"
                      className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full disabled:text-gray-500"
                    />
                    <ErrorMessage
                      name="legalLastName"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <label
                      htmlFor="phoneNumber"
                      className="text-sm font-bold text-gray-700"
                    >
                      Phone Number*
                    </label>
                    <Field
                      type="tel"
                      placeholder="Enter your Phone Number"
                      name="phoneNumber"
                      id="phoneNumber"
                      className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full disabled:text-gray-500"
                    />
                    <ErrorMessage
                      name="phoneNumber"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <label
                      htmlFor="dateOfBirth"
                      className="text-sm font-bold text-gray-700"
                    >
                      Date of Birth*
                    </label>
                    <Field
                      disabled
                      type="date"
                      placeholder="Date Of Birth"
                      name="dateOfBirth"
                      id="dateOfBirth"
                      className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full disabled:text-gray-500"
                    />
                    <ErrorMessage
                      name="dateOfBirth"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <label
                      htmlFor="address"
                      className="text-sm font-bold text-gray-700"
                    >
                      Address*
                    </label>
                    <Field
                      disabled
                      type="text"
                      placeholder="Enter your address"
                      name="address"
                      id="address"
                      className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full disabled:text-gray-500"
                    />
                    <ErrorMessage
                      name="address"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className="flex flex-col gap-2 max-w-md w-full">
                    <button
                      // disabled={!isValid || !dirty}
                      type="submit"
                      className="p-4 px-5 w-full border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
                    >
                      Confirm
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default CompleteProfile;
