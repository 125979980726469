import { useState } from "react";
import ChangePasswordModal from "../../../components/ChangePasswordModal";
import ChangePinModal from "../../../components/ChangePinModal";

const Security = () => {
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showPinModal, setShowPinModal] = useState(false);

  return (
    <>
      {showPasswordModal && (
        <ChangePasswordModal setShowPasswordModal={setShowPasswordModal} />
      )}
      {showPinModal && <ChangePinModal setShowPinModal={setShowPinModal} />}
      <div className="bg-gray-50 px-10 py-10 flex items-center justify-center">
        <div className="flex flex-col gap-8 max-w-3xl w-full">
          <div className="flex gap-10 items-center">
            <div>
              <div className="font-medium text-deepBlue text-lg">Security</div>
              <div className="text-gray-700">
                Update your security details here.
              </div>
            </div>
          </div>
          <div className="bg-white shadow rounded-lg w-full flex-col gap-5 flex">
            <div className="p-10 py-10 w-full flex-col gap-10 flex">
              <div className="flex flex-col gap-2 w-full">
                <span className="text-sm font-bold text-gray-700">
                  Password
                </span>
                <button
                  onClick={() => setShowPasswordModal(true)}
                  className="p-4 px-5 border-visaro-gray-300 shadow-sm border rounded-lg font-bold bg-white text-deepBlue text-sm w-fit"
                >
                  Change Password
                </button>
              </div>
              <div className="flex flex-col gap-2 w-full ">
                <span className="text-sm font-bold text-gray-700">
                  Transaction Pin
                </span>
                <button
                  onClick={() => setShowPinModal(true)}
                  className="p-4 px-5 border-visaro-gray-300 border shadow-sm rounded-lg font-bold bg-white text-deepBlue text-sm w-fit"
                >
                  Change Transaction Pin
                </button>
              </div>
              <div className="flex flex-col gap-2 w-full ">
                <span className="text-sm font-bold text-gray-700">
                  Two-factor Auth
                </span>
                <div>
                  <div>
                    <div className="text-gray-600 font-medium">Disabled</div>
                    <div className="text-gray-500">
                      If you lose access to your authentication device, use any
                      of your backup codes to login to your account. Generate
                      new backup codes.
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2 w-full ">
                <span className="text-sm font-bold text-gray-700">
                  Security Alerts
                </span>
                <div>
                  <div>
                    <div className="text-gray-600 font-medium">Disabled</div>
                    <div className="text-gray-500">
                      Get frequent notifications for every login, transaction
                      performed, and every change made to this account.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-t border-gray-100 p-10 py-5 flex items-center justify-end">
              <button
                type="submit"
                className="p-4 px-5 border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Security;
