import { CaretLeft, CaretRight, PhoneCall } from "phosphor-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const getUXWritings = (type: string) => {
  if (type === "data" || type === "airtime") {
    return {
      heading: "Telecommunications provider",
      desc: "Please select the telecom provider you are buying from",
    };
  }

  if (type === "electricity") {
    return {
      heading: "Distribution company",
      desc: "Please select the disco you are buying from",
    };
  }

  return {
    heading: "Provider",
    desc: "Please select Provider",
  };
};

export const getProviders = (type: string) => {
  if (type === "data" || type === "airtime") {
    return [
      {
        name: "mtn",
        title: "MTN",
        logo: "https://nigerialogos.com/logos/mtn/mtn.svg",
      },
      {
        name: "airtel",
        title: "Airtel",
        logo: "https://nigerialogos.com/logos/airtel/airtel.svg",
      },
      {
        name: "9mobile",
        title: "9mobile",
        logo: "https://nigerialogos.com/logos/9mobile/9mobile.svg",
      },
      {
        name: "glo",
        title: "Globacom",
        logo: "https://nigerialogos.com/logos/glo/glo.svg",
      },
    ];
  }

  return [];
};

const SelectProvider = () => {
  const navigate = useNavigate();
  const [selectedProvider, setSelectedProvider] = useState<string | null>(null);

  const { type } = useParams();

  return (
    <div>
      <div className="flex items-center justify-center py-5 gap-4 border-b border-gray-200">
        <div className="font-medium">Pay Bills</div>
        <CaretRight weight="bold" />
        <div className="font-medium">Provider</div>
        <CaretRight weight="bold" />
        <div className="font-medium text-gray-400">Details</div>
        <CaretRight weight="bold" className="text-gray-400" />
        <div className="font-medium text-gray-400">Confirm</div>
      </div>
      <div className="flex items-center justify-center gap-10 py-20 text-center flex-col">
        <div className="flex flex-col gap-2">
          <div className="font-semibold text-3xl">
            {getUXWritings(type as string).heading}
          </div>
          <div className="text-gray-500">
            {getUXWritings(type as string).desc}
          </div>
        </div>
        <div className="flex w-full justify-center">
          {type === "electricity" ? (
            <div className="col-span-full bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-5 max-w-3xl w-full">
              <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-2 w-full text-left">
                  <label htmlFor="bank" className="text-sm font-medium">
                    Select Provider
                  </label>
                  <select
                    placeholder="Select Provider"
                    id="bank"
                    onChange={(e) => setSelectedProvider(e.target.value)}
                    name="bank"
                    className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                  >
                    <option>Select Provider</option>
                    {getProviders("data").map((provider, key) => {
                      return (
                        <option key={key} value={provider.name}>
                          {provider.title}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          ) : (
            getProviders(type as string).map((provider, key) => {
              return (
                <button
                  key={key}
                  onClick={() => setSelectedProvider(provider.name)}
                  className={`c-groupedSelect__option ${
                    selectedProvider === provider.name && "active"
                  }`}
                >
                  <div className="bg-gray-100 rounded-full p-3 relative overflow-hidden">
                    <div className="w-full h-full absolute top-0 left-0 bg-gray-100 aspect-square p-2">
                      <img
                        src={provider.logo}
                        alt={provider.title}
                        className="aspect-square object-contain "
                      />
                    </div>
                    <PhoneCall weight="bold" size={18} />
                  </div>
                  <div>{provider.title}</div>
                </button>
              );
            })
          )}
        </div>
        <div className="flex justify-between w-full max-w-3xl">
          <button
            onClick={() => navigate(-1)}
            type="button"
            className="p-2 py-3 text-center bg-deepBlue text-white px-6 border-2 rounded-xl font-medium flex gap-2 items-center"
          >
            <CaretLeft />
            <span>Back</span>
          </button>
          <button
            onClick={() => navigate(selectedProvider || "")}
            disabled={!selectedProvider}
            className="p-2 py-3 text-center bg-deepBlue text-white px-10 border-2 rounded-xl font-medium"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectProvider;
