import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Index from "./lib/pages/Index";
import About from "./lib/pages/About";
import Business from "./lib/pages/Business";
import SignUp from "./lib/pages/SignUp";
import ForgotPassword from "./lib/pages/ForgotPassword";
import Login from "./lib/pages/Login";
import ChangePassword from "./lib/pages/ChangePassword";
import MVPLanding from "./lib/pages/MVPLanding";
import ContactUs from "./lib/pages/ContactUs";
import Legal from "./lib/pages/Legal";
import { QueryClient, QueryClientProvider } from "react-query";
import FAQ from "./lib/pages/FAQ";
import Blog from "./lib/pages/Blog";
import Discover from "./lib/pages/Discover";
import PersonalHome from "./lib/pages/personal/Home";
import BusinessHome from "./lib/pages/business/Home";
import ValidateOTP from "./lib/pages/ValidateOTP";
import PageNotFound from "./lib/pages/PageNotFound";
import AuthContext from "./lib/context/AuthContext";
import MVPLandingUser from "./lib/pages/MvplandingUser";
import GetStarted from "./lib/pages/signup/GetStarted";
import BankDetails from "./lib/pages/signup/BankDetails";
import BankVerified from "./lib/pages/signup/BankVerify";
import FacialCapture from "./lib/pages/signup/FacialCapture";
import FaceVerifySuccess from "./lib/pages/signup/FacialVerifySuccess";
import FaceVerifyFailed from "./lib/pages/signup/FacialVerifyFailed";
import ChooseUsername from "./lib/pages/signup/ChooseUsername";
import UsernameVerified from "./lib/pages/signup/UsernameSuccess";
// import TripBookingWebView from "./lib/components/TripBookingWebView";

console.clear();

const router = createBrowserRouter([
  {
    path: "/",
    element: <MVPLanding />,
  },
  {
    path: "/home",
    element: <Index />,
  },
  {
    path: "/for-personal",
    element: <Index />,
  },
  {
    path: "/legal",
    element: <Legal />,
  },
  {
    path: "/privacy",
    element: <Legal />,
  },
  {
    path: "/faq",
    element: <FAQ />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/for-business",
    element: <Business />,
  },
  {
    path: "/sign-up/*",
    element: <SignUp />,
  },
  {
    path: "/personal/*",
    element: <PersonalHome />,
  },
  {
    path: "/business/*",
    element: <BusinessHome />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/otp",
    element: <ValidateOTP />,
  },
  {
    path: "/discover",
    element: <Discover />,
  },

  {
    path: "/change-password",
    element: <ChangePassword />,
  },
  // {
  //   path: "/trip-booking",
  //   element: <TripBookingWebView />,
  // },
  {
    path: "/userland",
    element: <MVPLandingUser />,
  },
  {
    path: "/country",
    element: <GetStarted />,
  },
  {
    path: "/bank",
    element: <BankDetails />,
  },
  {
    path: "/bank-verify",
    element: <BankVerified />,
  },
  {
    path: "/face-verify",
    element: <FacialCapture />,
  },
  {
    path: "/face-verify-success",
    element: <FaceVerifySuccess />,
  },
  {
    path: "/face-verify-fail",
    element: <FaceVerifyFailed />,
  },
  {
    path: "/choose-username",
    element: <ChooseUsername />,
  },
  {
    path: "/username-verify",
    element: <UsernameVerified />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContext>
        <RouterProvider router={router} />
      </AuthContext>
    </QueryClientProvider>
  );
}

export default App;
