import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/visaro-logo.svg";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { HashLoader } from "react-spinners";
import useLogin from "../hooks/useLogin";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email is required"),
  password: yup
    .string()
    .min(6, "Password must be at least 8 characters long")
    .required("Password is required"),
});

const Login = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const [passwordIsVisible, setPasswordIsVisible] = useState(false);

  const { login, isLoading, isError, error } = useLogin();

  const navigate = useNavigate();

  return (
    <div>
      {isLoading && (
        <div className="fixed top-0 z-50 left-0 h-screen w-screen flex items-center justify-center bg-black/20 backdrop-blur-sm">
          <HashLoader />
        </div>
      )}
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={schema}
        onSubmit={(values, { resetForm }) => {
          login(values, {
            onSuccess(data) {
              localStorage.setItem("token", data.token);
              navigate(`/otp?email=${values.email}&redirect=personal`);
              resetForm();
            },
          });
        }}
      >
        {({ isValid, dirty }) => {
          return (
            <div className="w-screen h-screen grid grid-cols-1 md:grid-cols-2">
              <div className="flex justify-between flex-col h-screen">
                <nav className="p-4 md:px-10 flex items-center">
                  <Link to="/">
                    <img src={logo} alt="Visaro's Logo" />
                  </Link>
                </nav>
                <div className="flex flex-col justify-center items-start gap-8 text-lg w-full p-20 px-5 md:px-20">
                  <Form className="w-full flex-col gap-5 flex items-center justify-center text-base ">
                    <div className="flex flex-col gap-2 max-w-md w-full">
                      <div className="text-4xl md:text-3xl lg:text-5xl font-bold">
                        Log in
                      </div>
                      <div className="text-gray-500">
                        Welcome back! Please enter your details.
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 max-w-md w-full">
                      <label
                        htmlFor="email"
                        className="text-sm font-bold text-gray-700"
                      >
                        Email*
                      </label>
                      <Field
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter your email"
                        className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-500"
                      />
                      {error?.validationErrors?.email && (
                        <div className="text-red-500">
                          {error?.validationErrors?.email}
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col gap-2 max-w-md w-full">
                      <label
                        htmlFor="password"
                        className="text-sm font-bold text-gray-700"
                      >
                        Password*
                      </label>
                      <div className="rounded-lg border border-gray-300 shadow flex items-center w-full relative overflow-hidden focus-within:outline-deepBlue focus-within:outline accent-deepBlue">
                        <Field
                          autoComplete="off"
                          type={passwordIsVisible ? "text" : "password"}
                          id="password"
                          name="password"
                          placeholder="Create a password"
                          className="px-4 py-3 bg-transparent flex-1 rounded-lg focus:outline-none"
                        />
                        <div
                          onClick={() => setPasswordIsVisible((prev) => !prev)}
                          className="absolute right-0 m-4 cursor-pointer text-sm"
                        >
                          {passwordIsVisible ? "Hide" : "Show"}
                        </div>
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-red-500"
                      />
                      {error?.validationErrors?.password && (
                        <div className="text-red-500">
                          {error?.validationErrors?.password}
                        </div>
                      )}
                    </div>
                    <div className="flex gap-2 max-w-md w-full items-center justify-between">
                      <div className="flex gap-2 items-center">
                        <input
                          type="checkbox"
                          name="rememberMe"
                          id="rememberMe"
                          className="accent-visaro-primary-700"
                        />
                        <label htmlFor="rememberMe">Remember for 30 days</label>
                      </div>
                      <Link
                        to="/forgot-password"
                        className="text-visaro-primary-700 font-medium"
                      >
                        Forgot Password
                      </Link>
                    </div>
                    <div className="flex flex-col gap-2 max-w-md w-full">
                      {isError && (
                        <div className="text-red-500 text-left flex">
                          {"Incorrect Email or Password"}
                        </div>
                      )}
                      <button
                        disabled={!isValid || !dirty}
                        type="submit"
                        className="p-4 px-5 w-full border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
                      >
                        {isLoading ? "Loading" : "Sign in"}
                      </button>
                    </div>
                    <div className="flex flex-col gap-2 max-w-md w-full">
                      <button
                        type="button"
                        className="p-4 px-5 w-full border-gray-300 text-gray-700 border rounded-lg font-bold text-sm flex gap-4 items-center justify-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 186.69 190.5"
                          className="w-6 h-6"
                        >
                          <g transform="translate(1184.583 765.171)">
                            <path
                              clipPath="none"
                              mask="none"
                              d="M-1089.333-687.239v36.888h51.262c-2.251 11.863-9.006 21.908-19.137 28.662l30.913 23.986c18.011-16.625 28.402-41.044 28.402-70.052 0-6.754-.606-13.249-1.732-19.483z"
                              fill="#4285f4"
                            />
                            <path
                              clipPath="none"
                              mask="none"
                              d="M-1142.714-651.791l-6.972 5.337-24.679 19.223h0c15.673 31.086 47.796 52.561 85.03 52.561 25.717 0 47.278-8.486 63.038-23.033l-30.913-23.986c-8.486 5.715-19.31 9.179-32.125 9.179-24.765 0-45.806-16.712-53.34-39.226z"
                              fill="#34a853"
                            />
                            <path
                              clipPath="none"
                              mask="none"
                              d="M-1174.365-712.61c-6.494 12.815-10.217 27.276-10.217 42.689s3.723 29.874 10.217 42.689c0 .086 31.693-24.592 31.693-24.592-1.905-5.715-3.031-11.776-3.031-18.098s1.126-12.383 3.031-18.098z"
                              fill="#fbbc05"
                            />
                            <path
                              d="M-1089.333-727.244c14.028 0 26.497 4.849 36.455 14.201l27.276-27.276c-16.539-15.413-38.013-24.852-63.731-24.852-37.234 0-69.359 21.388-85.032 52.561l31.692 24.592c7.533-22.514 28.575-39.226 53.34-39.226z"
                              fill="#ea4335"
                              clipPath="none"
                              mask="none"
                            />
                          </g>
                        </svg>
                        <span>Sign Up With Google</span>
                      </button>
                    </div>
                    <div className="flex flex-col gap-2 max-w-md w-full">
                      <Link to="/sign-up" className="text-center text-gray-600">
                        Don't have an account{" "}
                        <span className="text-visaro-orange">Sign Up</span>
                      </Link>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="h-screen overflow-hidden hidden md:block">
                <img
                  src={require("../assets/images/visaro-man-calling.png")}
                  className="h-screen w-full object-cover"
                  alt=""
                />
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default Login;
