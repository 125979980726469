import React, { FC, useEffect } from "react";
import { get } from "../helpers/api";
import { useQuery } from "react-query";
import { API_URL } from "../../config";
import { APIResponse, Region } from "../@types";
import SelectInput from "./SelectInput";

const RegionInput: FC<{
  countryKey: string;
  onSelect: (regionId: string) => void;
  selected: string;
}> = ({ countryKey, onSelect, selected }) => {
  const { isLoading, data, isError, refetch } = useQuery<APIResponse>(
    "regions",
    () => get(API_URL + `/region_list/${countryKey}`)
  );

  useEffect(() => {
    refetch();
  }, [countryKey, refetch]);
  return (
    <div>
      <SelectInput
        selected={selected}
        onSelect={onSelect}
        data={(data?.data as Region[]).map((region) => ({
          id: region.id,
          value: region.description,
        }))}
        disabled={isLoading}
        placeholder={
          isLoading
            ? "Fetching regions"
            : isError
            ? "Error fetching regions"
            : "Select Region"
        }
      />
    </div>
  );
};

export default RegionInput;
