import { DotsThreeVertical } from "phosphor-react";
import visa from "../../assets/images/visa.svg";
import mastercard from "../../assets/images/visaro-master-card.png";

const Wallet = () => {
  return (
    <div>
      <div className="grid grid-cols-2 gap-5 p-10 bg-gray-50">
        <div className="flex flex-col gap-5">
          <div className="col-span-full bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-5">
            <div className="flex justify-between items-center">
              <div className="text-lg">Cards</div>
              <DotsThreeVertical size={18} weight="bold" />
            </div>
            <div className="flex flex-col gap-5">
              <div className="flex items-center gap-5 cursor-pointer">
                <img src={visa} alt="logo" />
                <div>
                  <div className="font-medium">Visa ending in 1234</div>
                  <div className="text-gray-500">Expiry 06/2028</div>
                </div>
              </div>
              <div className="flex items-center gap-5 cursor-pointer">
                <img src={mastercard} alt="logo" />
                <div>
                  <div className="font-medium">Mastercard ending in 1234</div>
                  <div className="text-gray-500">Expiry 06/2028</div>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center">
              <button className="text-blue-600 font-medium opacity-80 hover:opacity-100 transition">
                Link a Card
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <div className="col-span-1 bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-5">
            <div className="flex items-start">
              <div className="bg-gradient-to-r from-blue-800 to-indigo-900 p-5 rounded-3xl max-w-sm w-full aspect-video flex flex-col text-white">
                <div className="flex-1 flex justify-end items-start">
                  <img src={visa} alt="logo" className="w-12" />
                </div>
                <div>
                  <div className="flex items-center justify-between">
                    <div className="font-grotesk uppercase">Olivia Rhye</div>
                    <div className="font-grotesk">06/24</div>
                  </div>
                  <div className="text-2xl tracking-wider font-grotesk">
                    1234 1234 1234 1234
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="text-lg font-medium">Visa Debit **0756</div>
              <button className="text-blue-600 font-medium opacity-80 hover:opacity-100 transition">
                Give it a nickname
              </button>
            </div>
            <div>
              <div className="text-lg font-medium">Expiration Date</div>
              <div className="text-gray-500 font-medium">11/2023</div>
            </div>
            <div className="flex flex-col gap-5">
              <div className="text-lg font-medium">Card Preference</div>
              <div className="flex flex-col items-center justify-center gap-8">
                <div className="flex flex-col items-center justify-center gap-2 text-center">
                  <div className="text-lg font-medium">
                    Preferred when paying online
                  </div>
                  <button className="text-blue-600 font-medium opacity-80 hover:opacity-100 transition">
                    Set as preferred
                  </button>
                  <div className="text-gray-500 max-w-md text-center">
                    We’ll use this when you shop or send money for goods and
                    services.
                  </div>
                </div>
                <div className="flex gap-2">
                  <button className="p-2 py-3 px-8 text-center  border-gray-300 text-gray-600 bg-gray-50 border rounded-xl font-medium">
                    Update Card
                  </button>
                  <button className="p-2 py-3 px-8 text-center bg-red-100 text-red-600 font-semibold rounded-xl">
                    Remove Card
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1 bg-white drop-shadow-sm p-5 rounded-xl flex flex-col gap-5">
            <div className="text-lg max-w-[50%]">
              Want to use your Visaro account with no spending or withdrawal
              limits?
            </div>
            <div className="flex justify-between items-center">
              <button className="text-blue-600 font-medium opacity-80 hover:opacity-100 transition">
                Confirm Your Card
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
