import React from "react";
import { useAuth } from "../../../context/AuthContext";
import { Form, Formik } from "formik";
import { formatAsMoney } from "../../../helpers/numbers";

const Preferences = () => {
  const { user } = useAuth();

  return (
    <div className="bg-gray-50 px-10 py-10 flex items-center justify-center">
      <div className="flex flex-col gap-8 max-w-3xl w-full">
        <div className="flex gap-10 items-center">
          <div>
            <div className="font-medium text-deepBlue text-lg">Peferences</div>
            <div className="text-gray-700">
              Update your preferences and make changes to your account here
            </div>
          </div>
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            firstname: user?.first_name,
            lastname: user?.last_name,
            phoneNumber: user?.phone_2,
            address: user?.address
              ? `${user?.address || ""}, ${
                  user.state_of_residence?.description || ""
                }`
              : "",
          }}
          onSubmit={() => {}}
        >
          <Form className="bg-white rounded-lg w-full shadow-sm flex-col gap-5 flex">
            <div className="p-10 py-10 w-full flex-col gap-5 flex">
              <div className="flex flex-col gap-2 w-full ">
                <label
                  htmlFor="email"
                  className="text-sm font-bold text-gray-700"
                >
                  Default Currency
                </label>
                <select className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full">
                  <option value="ngn">NGN</option>
                </select>
              </div>
              <div className="flex flex-col gap-2 w-full ">
                <label
                  htmlFor="email"
                  className="text-sm font-bold text-gray-700"
                >
                  Preferred Language
                </label>
                <select className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full">
                  <option value="us">English US</option>
                  <option value="uk">English UK</option>
                </select>
              </div>
              <div className="flex flex-col gap-2 w-full ">
                <label
                  htmlFor="email"
                  className="text-sm font-bold text-gray-700"
                >
                  Transaction Limit (monthly)
                </label>
                <select className="rounded-lg border border-gray-300 shadow px-4 py-3 w-full">
                  <option value="us">{formatAsMoney(100000)}</option>
                  <option value="us">{formatAsMoney(1000000)}</option>
                </select>
              </div>
              <div className="flex flex-col gap-4 w-full ">
                <label
                  htmlFor="email"
                  className="text-sm font-bold text-gray-700"
                >
                  Appearance
                </label>
                <div className="flex flex-col gap-4">
                  <div className="flex gap-3 items-center">
                    <input
                      type="checkbox"
                      name="dark"
                      id="dark"
                      className="accent-visaro-primary-700 w-5 aspect-square rounded-lg"
                    />
                    <label
                      className="text-visaro-gray-700 font-medium"
                      htmlFor="dark"
                    >
                      Dark
                    </label>
                  </div>
                  <div className="flex gap-3 items-center">
                    <input
                      type="checkbox"
                      name="light"
                      id="light"
                      className="accent-visaro-primary-700 w-5 aspect-square"
                    />
                    <label
                      className="text-visaro-gray-700 font-medium"
                      htmlFor="light"
                    >
                      Light
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-t border-gray-100 p-10 py-5 flex items-center justify-between">
              <button
                type="submit"
                className="p-4 px-5 border-red-100 border rounded-lg font-bold bg-red-100 text-red-500 text-sm"
              >
                Log Out
              </button>
              <button
                type="submit"
                className="p-4 px-5 border-visaro-gray-700 border rounded-lg font-bold bg-deepBlue text-white text-sm"
              >
                Save Changes
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default Preferences;
